import React, { useEffect, useState } from 'react';
import { Container, Paper, Title, Text } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { notifications } from '@mantine/notifications';

const ProviderPracticeEmailVerificationPage: React.FC = () => {
  const { enrollmentcode } = useParams<{ enrollmentcode: string }>();
  const [verificationStatus, setVerificationStatus] = useState<string | null>(null);

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await fetch(`/api/provider/practice/emailverification/${enrollmentcode}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          setVerificationStatus('success');
          notifications.show({
            title: 'Verification Successful',
            message: 'Your email has been verified successfully.',
            color: 'green',
            position: 'bottom-right',
          });
        } else {
          throw new Error('Verification failed');
        }
      } catch (error) {
        console.error('Error verifying email:', error);
        setVerificationStatus('error');
        notifications.show({
          title: 'Verification Failed',
          message: 'There was an issue verifying your email. Please try again later.',
          color: 'red',
          position: 'bottom-right',
        });
      }
    };

    if (enrollmentcode) {
      verifyEmail();
    }
  }, [enrollmentcode]);

  return (
    <Container>
      <Paper shadow="md" p="xl" withBorder>
        <Title order={2} ta="center" mb="sm">
          {verificationStatus === 'success'
            ? 'Thank you for verifying your email!'
            : 'Verifying your email...'}
        </Title>
        {verificationStatus === 'success' ? (
          <Text ta="center">
            Thank you for your interest in Ryze.
            We will contact you shortly with more information.
          </Text>
        ) : (
          <Text ta="center">Please wait while we verify your email.</Text>
        )}
      </Paper>
    </Container>
  );
};

export default ProviderPracticeEmailVerificationPage;
