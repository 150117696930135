import { useState, useMemo } from 'react';
import { Grid, Text, Paper, Container, Title } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import useHttpClient from './hooks/useHttpClient';
import { useMantineColorScheme, useComputedColorScheme } from '@mantine/core';
import { useAuth } from './hooks/useAuth';
import { useAuth as useAuth2 } from './AuthProvider';
import Widget1 from './DashboardWidgets/Widget1';

export default function Dashboard() {
  const { setColorScheme } = useMantineColorScheme();
  const computedColorScheme = useComputedColorScheme('light');
  const toggleColorScheme = () => {
    setColorScheme(computedColorScheme === 'dark' ? 'light' : 'dark');
  };

  const httpClient = useHttpClient();
  const navigate = useNavigate();
  const { organizationId, subscriberMemberId, wecareMemberId } = useAuth();
  const [userInfo, setUserInfo] = useState<any>();
  const [loading, setIsloading] = useState<boolean>(false);

  const auth = useAuth2();
  const privileges = auth.getPrivileges();
  const username = privileges[0].username;
  const types = (privileges || [])
    .map((priv) => priv.type)
    .join(',')
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase());

  const fetchUserInformation = async () => {
    console.log('subscriberMemberId', subscriberMemberId);
    setIsloading(true);
    try {
      if (subscriberMemberId) {
        const { data } = await httpClient.get(
          `/api/subscriber/${subscriberMemberId}`,
        );
        setUserInfo(data);
      }
      if (organizationId) {
        const { data } = await httpClient.get(`/api/organization`);
        setUserInfo((setUserInfo) => {
          return { ...setUserInfo, ...data };
        });
      }
    } catch (error) {
      console.error('failed to fetch user information', error);
    } finally {
      setIsloading(false);
    }
  };

  useMemo(() => {
    fetchUserInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriberMemberId, organizationId]);

  return (
    <Container size="xl">
      <Paper
        withBorder
        shadow="none"
        p={0}
        radius="md"
        style={{ overflow: 'hidden', maxHeight: '800px' }}
      >
        <Grid>
          <Grid.Col span={6} p="xl">
            <Title
              order={3}
              mt="20"
              style={{ textAlign: 'left', marginBottom: '2rem' }}
            >
              Welcome Back! {userInfo?.firstname}
            </Title>
            <Text mb="xl">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero
              necessitatibus ipsa natus? Quaerat eaque quos asperiores voluptate
              ea in, porro, neque ducimus adipisci dicta, commodi minima eum
              tempore soluta omnis. Lorem ipsum dolor sit amet consectetur
              adipisicing elit. Vero necessitatibus ipsa natus? Quaerat eaque
              quos asperiores voluptate ea in, porro, neque ducimus adipisci
              dicta, commodi minima eum tempore soluta omnis. tempore soluta
              omnis.
              <br />
              <br />
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero
              necessitatibus ipsa natus? Quaerat eaque quos asperiores voluptate
              ea in, porro, neque ducimus adipisci dicta, commodi minima eum
              tempore soluta omnis.
            </Text>
            <Widget1 />
          </Grid.Col>
          <Grid.Col
            span={6}
            style={{
              backgroundImage: 'url("/bg.webp")', // Replace with your image URL
              backgroundPosition: 'right center', // Aligns the image to the right
              backgroundSize: 'cover', // Scales the image to cover the container
              backgroundRepeat: 'no-repeat',
              height: '100vh', // Ensures full height of the column
            }}
          ></Grid.Col>
        </Grid>
      </Paper>
    </Container>
  );
}
