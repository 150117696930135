import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import useHttpClient from './hooks/useHttpClient';
import { SubscriberMember } from './../types/subscribermember.types';
import { useAuth } from './hooks/useAuth';

interface OptionalWecareMemberContextProps {
  isNewMember: boolean;
  setIsNewMember: (isNew: boolean) => void;
  memberId: number | null;
  setMemberId: (id: number | null) => void;
  member: SubscriberMember | null;
  isLoading: boolean;
  error: string | null;
}

const OptionalWecareMemberContext = createContext<
  OptionalWecareMemberContextProps | undefined
>(undefined);

interface OptionalWecareMemberProviderProps {
  children: ReactNode;
}

export const OptionalWecareMemberProvider: React.FC<
  OptionalWecareMemberProviderProps
> = ({ children }) => {
  const [member, setMember] = useState<SubscriberMember | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isNewMember, setIsNewMember] = useState<boolean>(false);
  const [memberId, setMemberId] = useState<number | null>(null); // Now it can be null if the user isn't a WeCare member
  const httpClient = useHttpClient();
  const { wecareMemberId } = useAuth();

  const fetchMemberData = async (id: number) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await httpClient.get(`/api/members/${id}`);
      const data = await response.data;
      setMember(data);
      setMemberId(id);
    } catch (err) {
      console.error('Error fetching WeCare member data', err);
      setError('Failed to fetch WeCare member data');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (wecareMemberId !== null && !isNewMember) {
      fetchMemberData(wecareMemberId);
    }
  }, [wecareMemberId, isNewMember]);

  return (
    <OptionalWecareMemberContext.Provider
      value={{
        isNewMember,
        setIsNewMember,
        memberId,
        setMemberId,
        member,
        isLoading,
        error,
      }}
    >
      {children}
    </OptionalWecareMemberContext.Provider>
  );
};

// Custom hook to use the OptionalWecareMemberContext
export const useOptionalWecareMemberContext =
  (): OptionalWecareMemberContextProps => {
    const context = useContext(OptionalWecareMemberContext);
    if (!context) {
      throw new Error(
        'useOptionalWecareMemberContext must be used within an OptionalWecareMemberProvider',
      );
    }
    return context;
  };
