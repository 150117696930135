import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Select,
  Group,
  Collapse,
  Paper,
  Grid,
  Input,
} from '@mantine/core';
import { IMaskInput } from 'react-imask';
import { notifications } from '@mantine/notifications';
import { useMemberContext } from './MemberContext';
import useHttpClient from './hooks/useHttpClient';
import { STATES_ARRAY } from '../types/members.types';

type FormValues = {
  caqhnumber: string;
  caqhuserid: string;
  caqhpassword: string;
  credentialingContacts: CredentialingContactFields[];
};

type CredentialingContactFields = {
  id?: number; // Ensure ID is included for updates
  firstname: string;
  middlename: string;
  lastname: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  province: string;
  phone: string;
  fax: string;
  email: string;
  primarycontact: string;
  locationtype: string;
  location: string;
};

const states = STATES_ARRAY;

interface FormProps {
  memberId: number;
}

const CredentialingContactForm: React.FC<FormProps> = ({ memberId }) => {
  const { control, register, handleSubmit, reset } = useForm<FormValues>({});
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'credentialingContacts',
  });

  const [collapsedIndices, setCollapsedIndices] = useState<boolean[]>([]);
  const [initialData, setInitialData] = useState<any>();

  const httpClient = useHttpClient();

  const fetchCredentialingContacts = async () => {
    console.log('Fetching credentialing contacts for memberId:', memberId); // Debug statement
    try {
      const contactsResponse = await httpClient.get(
        `/api/credentialingcontacts/${memberId}`,
      );
      console.log(
        'Credentialing contacts response status:',
        contactsResponse.status,
      ); // Debug statement

      const fetchedContacts = contactsResponse.data;
      console.log('Fetched credentialing contacts:', fetchedContacts); // Debug statement

      const sortedContacts = fetchedContacts.sort((a: any, b: any) => {
        const lastNameComparison = a.lastname.localeCompare(b.lastname);
        if (lastNameComparison !== 0) return lastNameComparison;
        const firstNameComparison = a.firstname.localeCompare(b.firstname);
        if (firstNameComparison !== 0) return firstNameComparison;
        return a.id - b.id;
      });

      const caqhResponse = await httpClient.get(`/api/caqh/${memberId}`);
      console.log('CAQH response status:', caqhResponse.status); // Debug statement

      let fetchedCAQH = { caqhnumber: '', caqhuserid: '', caqhpassword: '' };

      if (caqhResponse.data) {
        fetchedCAQH = caqhResponse.data;
      }
      console.log('Fetched CAQH:', fetchedCAQH); // Debug statement

      reset({ credentialingContacts: sortedContacts, ...fetchedCAQH });
      setInitialData({
        credentialingContacts: sortedContacts,
        ...fetchedCAQH,
      });
      setCollapsedIndices(sortedContacts.map(() => false));
    } catch (error) {
      console.error('Error fetching credentialing contacts:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch credentialing contacts',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  useEffect(() => {
    if (memberId) {
      fetchCredentialingContacts();
    }
  }, [memberId]);

  const toggleCollapse = (index: number) => {
    setCollapsedIndices((prev) => {
      const newCollapsedIndices = [...prev];
      newCollapsedIndices[index] = !newCollapsedIndices[index];
      return newCollapsedIndices;
    });
  };

  const onSubmit = async (data: FormValues) => {
    console.log('Submit form values:', data); // Debug statement

    const formatData = (items: CredentialingContactFields[]) => {
      return items.map((item) => ({
        ...item,
        memberid: memberId,
      }));
    };

    const formattedData = formatData(data.credentialingContacts);

    const createContact = async (contact: any) => {
      console.log('Creating contact:', contact); // Debug statement
      const response = await fetch('/api/credentialingcontacts', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(contact),
      });

      console.log('Create contact response:', response); // Debug statement
      return response.json();
    };

    const updateContact = async (contact: any) => {
      console.log('Updating contact:', contact); // Debug statement
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/credentialingcontacts/${contact.id}`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(contact),
        },
      );

      console.log('Update contact response:', response); // Debug statement
      return response.json();
    };

    const deleteContact = async (id: number) => {
      console.log('Deleting contact ID:', id); // Debug statement
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/credentialingcontacts/${id}`,
        {
          method: 'DELETE',
        },
      );

      console.log('Delete contact response:', response); // Debug statement
    };

    const createCAQH = async (caqhData: any) => {
      const caqhDataWithMemberId = { ...caqhData, memberid: memberId }; // Ensure memberid is included
      console.log('Creating CAQH:', caqhDataWithMemberId); // Debug statement
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/caqh`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(caqhDataWithMemberId),
        },
      );

      console.log('Create CAQH response:', response); // Debug statement
      return response.json();
    };

    const updateCAQH = async (caqhData: any) => {
      const caqhDataWithMemberId = { ...caqhData, memberid: memberId }; // Ensure memberid is included
      console.log('Updating CAQH:', caqhDataWithMemberId); // Debug statement
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/caqh/${memberId}`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(caqhDataWithMemberId),
        },
      );

      console.log('Update CAQH response:', response); // Debug statement
      return response.json();
    };

    const deleteCAQH = async () => {
      console.log('Deleting CAQH for memberId:', memberId); // Debug statement
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/caqh/${memberId}`,
        {
          method: 'DELETE',
        },
      );

      console.log('Delete CAQH response:', response); // Debug statement
    };

    // Determine deletions, creations, and updates
    const initialIds = new Set<number>(
      initialData?.credentialingContacts.map((c: any) => c.id),
    );
    const currentIds = new Set<number>(formattedData.map((c: any) => c.id));
    const deletedIds = [...initialIds].filter(
      (id): id is number => !currentIds.has(id),
    );

    const createTasks = formattedData
      .filter((c: any) => !c.id || c.id === 0)
      .map((c: any) => createContact(c));
    const updateTasks = formattedData
      .filter((c: any) => c.id && c.id > 0 && initialIds.has(c.id))
      .map((c: any) => updateContact(c));

    const deleteTasks = deletedIds.map((id) => deleteContact(id));

    let caqhTask = null;
    if (data.caqhnumber || data.caqhuserid || data.caqhpassword) {
      caqhTask =
        initialData?.caqhnumber ||
          initialData?.caqhuserid ||
          initialData?.caqhpassword
          ? updateCAQH({
            caqhnumber: data.caqhnumber,
            caqhuserid: data.caqhuserid,
            caqhpassword: data.caqhpassword,
          })
          : createCAQH({
            caqhnumber: data.caqhnumber,
            caqhuserid: data.caqhuserid,
            caqhpassword: data.caqhpassword,
          });
    } else if (
      initialData?.caqhnumber ||
      initialData?.caqhuserid ||
      initialData?.caqhpassword
    ) {
      caqhTask = deleteCAQH();
    }

    try {
      const results = await Promise.all(
        [...createTasks, ...updateTasks, ...deleteTasks, caqhTask].filter(
          Boolean,
        ),
      ); // Filter out null tasks
      console.log('Results:', results); // Debug statement
      fetchCredentialingContacts();

      notifications.show({
        title: 'Success',
        message: 'Credentialing contacts updated successfully',
        color: 'green',
        position: 'bottom-right',
      });
    } catch (error) {
      console.error('Error submitting credentialing contact form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit credentialing contact',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  return (
    <Container>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <Paper shadow="xs" p="sm" withBorder>
          <TextInput label="CAQH Number" {...register('caqhnumber')} />
          <Grid>
            <Grid.Col span={6}>
              <TextInput label="CAQH User ID" {...register('caqhuserid')} />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="CAQH Password"
                type="password"
                {...register('caqhpassword')}
              />
            </Grid.Col>
          </Grid>
        </Paper>

        {fields.map((item, index) => (
          <Paper key={item.id} shadow="xs" p="md" withBorder>
            <Group>
              <h3>Credentialing Contact {index + 1}</h3>
              <Button onClick={() => toggleCollapse(index)} size="xs">
                {collapsedIndices[index] ? 'Expand' : 'Collapse'}
              </Button>
              <Button color="red" onClick={() => remove(index)} size="xs">
                Remove
              </Button>
            </Group>
            <Collapse in={!collapsedIndices[index]}>
              <Grid>
                <Grid.Col span={6}>
                  <TextInput
                    label="First Name"
                    {...register(`credentialingContacts.${index}.firstname`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Last Name"
                    {...register(`credentialingContacts.${index}.lastname`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Middle Name"
                    {...register(`credentialingContacts.${index}.middlename`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label={'\u0405treet 1'}
                    {...register(`credentialingContacts.${index}.street1`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label={'\u0405treet 2'}
                    {...register(`credentialingContacts.${index}.street2`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="City"
                    {...register(`credentialingContacts.${index}.city`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`credentialingContacts.${index}.state`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="State"
                        data={states.map((state) => ({
                          value: state,
                          label: state,
                        }))}
                        clearable
                        searchable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="ZIP"
                    {...register(`credentialingContacts.${index}.zip`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`credentialingContacts.${index}.country`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Country"
                        data={[{ value: 'US', label: 'United States' }]}
                        clearable
                        searchable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Province"
                    {...register(`credentialingContacts.${index}.province`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`credentialingContacts.${index}.phone`}
                    control={control}
                    render={({ field }) => (
                      <Input.Wrapper label="Phone">
                        <Input
                          component={IMaskInput}
                          {...field}
                          mask="(000) 000-0000"
                          placeholder="(XXX) XXX-XXXX"
                        />
                      </Input.Wrapper>
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`credentialingContacts.${index}.fax`}
                    control={control}
                    render={({ field }) => (
                      <Input.Wrapper label="Fax">
                        <Input
                          component={IMaskInput}
                          {...field}
                          mask="(000) 000-0000"
                          placeholder="(XXX) XXX-XXXX"
                        />
                      </Input.Wrapper>
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Email"
                    {...register(`credentialingContacts.${index}.email`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`credentialingContacts.${index}.primarycontact`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Is this your primary credentialing contact?"
                        data={[
                          { value: 'Y', label: 'Yes' },
                          { value: 'N', label: 'No' },
                        ]}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Location Type"
                    {...register(`credentialingContacts.${index}.locationtype`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Location (Practice ID)"
                    {...register(`credentialingContacts.${index}.location`)}
                  />
                </Grid.Col>
              </Grid>
            </Collapse>
          </Paper>
        ))}
        <Group mt="md">
          <Button
            onClick={() =>
              append({
                firstname: '',
                middlename: '',
                lastname: '',
                street1: '',
                street2: '',
                city: '',
                state: '',
                zip: '',
                country: 'US',
                province: '',
                phone: '',
                fax: '',
                email: '',
                primarycontact: '',
                locationtype: '',
                location: '',
              })
            }
          >
            Add Credentialing Contact
          </Button>
        </Group>

        <Group mt="md">
          <Button type="submit">Save</Button>
        </Group>
      </form>
    </Container>
  );
};

export default CredentialingContactForm;
