import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Select,
  Paper,
  Input,
  MultiSelect,
  Grid,
  Title,
} from '@mantine/core';
import CustomDateInput from './CustomDateInput';
import { IMaskInput } from 'react-imask';
import { useMemberContext } from './MemberContext';
import { Member, STATES_ARRAY, LANGUAGES } from '../types/members.types';
import { notifications } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import useHttpClient from './hooks/useHttpClient';

interface MemberFormProps {
  member?: Member;
  setMember: (member: Member) => void;
  memberId: number;
  onMemberCreated?: (newMemberId: number) => void;
  setHasUnsavedChanges: (hasChanges: boolean) => void;
}

const MemberForm: React.FC<MemberFormProps> = ({
  member,
  setMember,
  memberId,
  onMemberCreated,
  setHasUnsavedChanges,
}) => {
  const { control, register, handleSubmit, reset, watch } = useForm({
    defaultValues: member,
  });

  const { isNewMember } = useMemberContext();
  const navigate = useNavigate();
  const httpClient = useHttpClient();

  const watchedFields = watch();

  useEffect(() => {
    if (member) {
      reset(member);
      setHasUnsavedChanges(false);
    }
  }, [member, reset, setHasUnsavedChanges]);

  useEffect(() => {
    console.log(
      `watched fields ${JSON.stringify(watchedFields)} 
      member ${JSON.stringify(member)}`,
    );
    const hasChanges = !isEqual(watchedFields, member);
    console.log('has changes', hasChanges);
    setHasUnsavedChanges(hasChanges);
  }, [watchedFields, member, setHasUnsavedChanges]);

  const onSubmit = async (data: Member) => {
    try {
      let newInitialData = member;

      if (isNewMember) {
        const response = await httpClient.post('/api/members', data);
        const newMember = response.data;

        // Post empty disclosures for the new member
        await httpClient.post('/api/disclosures', {
          memberid: newMember.id,
          licenserelinquished: null,
          licenserelinquisheddescription: '',
          challengetolicensure: null,
          challengetolicensuredescription: '',
          clinicalprivileges: null,
          clinicalprivilegesdescription: '',
          surrenderedprivileges: null,
          surrenderedprivilegesdescription: '',
          terminatedforcause: null,
          terminatedforcausedescription: '',
          placedonprobation: null,
          placedonprobationdescription: '',
          withdrawnunderinvestigation: null,
          withdrawnunderinvestigationdescription: '',
          revokedboardcertifications: null,
          revokedboardcertificationsdescription: '',
          notrecertifyboardcertifications: null,
          notrecertifyboardcertificationsdescription: '',
          deacdschallenges: null,
          deacdschallengesdescription: '',
          disciplinedbygovernmentprograms: null,
          disciplinedbygovernmentprogramsdescription: '',
          refusedprofessionalinsurance: null,
          refusedprofessionalinsurancedescription: '',
          deniedprofessionalinsurance: null,
          deniedprofessionalinsurancedescription: '',
        });

        onMemberCreated && onMemberCreated(newMember.id);

        // Navigate to the new member's page
        navigate(`/member/${newMember.id}`);
      } else {
        await httpClient.put(`/api/members/${memberId}`, data);
        console.log('Form updated successfully');

        // Update initial data with the updated data
        newInitialData = data;
      }

      // Update member with the new data
      setMember(newInitialData);

      // Reset the form with the new initial data
      reset(newInitialData);
      setHasUnsavedChanges(false);

      notifications.show({
        title: 'Success',
        message: isNewMember
          ? 'Member added successfully'
          : 'Member updated successfully',
        color: 'green',
        position: 'bottom-right',
      });
    } catch (error) {
      console.error('Error updating member', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to update member',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };
  return (
    <Container>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <Grid>
          <Grid.Col span={4}>
            <TextInput
              label="Legal First Name"
              required
              {...register('firstname', { required: true })}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TextInput
              label="Last Name"
              required
              {...register('lastname', { required: true })}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TextInput label="Middle Name" {...register('middlename')} />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={6}>
            <Controller
              name="sex"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label="Sex at Birth"
                  data={[
                    { value: 'M', label: 'M' },
                    { value: 'F', label: 'F' },
                  ]}
                  clearable
                />
              )}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <CustomDateInput
              control={control}
              name="birthday"
              label="Date of Birth"
              preventFutureDates
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={6}>
            <TextInput label="Birth City" {...register('birthcity')} />
          </Grid.Col>
          <Grid.Col span={6}>
            <Controller
              name="birthstate"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label="Birth State"
                  data={STATES_ARRAY.map((state) => ({
                    value: state,
                    label: state,
                  }))}
                  clearable
                  searchable
                />
              )}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={6}>
            <Controller
              name="ssn"
              control={control}
              render={({ field }) => (
                <Input.Wrapper label="SSN">
                  <Input
                    component={IMaskInput}
                    {...field}
                    mask="000-00-0000"
                    placeholder="XXX-XX-XXXX"
                  />
                </Input.Wrapper>
              )}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Controller
              name="mobilephone"
              control={control}
              render={({ field }) => (
                <Input.Wrapper label="Personal Mobile Phone">
                  <Input
                    component={IMaskInput}
                    {...field}
                    mask="(000) 000-0000"
                    placeholder="(XXX) XXX-XXXX"
                  />
                </Input.Wrapper>
              )}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={6}>
            <TextInput label="Personal Email" {...register('email')} />
          </Grid.Col>
          <Grid.Col span={6}>
            <Controller
              name="preferredcommunication"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label="Preferred Communication"
                  data={[
                    { value: 'Email', label: 'Email' },
                    { value: 'Phone', label: 'Phone' },
                  ]}
                  clearable
                />
              )}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={6}>
            <Controller
              name="caretype"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label="Care Type"
                  data={[
                    { value: 'Primary', label: 'Primary Only' },
                    { value: 'Specialty', label: 'Specialty Only' },
                    { value: 'Both', label: 'Both' },
                  ]}
                  clearable
                />
              )}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Controller
              name="ipa"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label="IPA"
                  data={[
                    { value: 'WeCare IPA', label: 'WeCare IPA' },
                    { value: 'NY IPA', label: 'NY IPA' },
                  ]}
                  clearable
                />
              )}
            />
          </Grid.Col>
        </Grid>
        <Controller
          name="raceethnicity"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              label="Race/Ethnicity"
              data={[
                { value: 'Asian', label: 'Asian' },
                {
                  value: 'Black or African American',
                  label: 'Black or African American',
                },
                { value: 'Hispanic or Latino', label: 'Hispanic or Latino' },
                {
                  value: 'Native American or Alaska Native',
                  label: 'Native American or Alaska Native',
                },
                {
                  value: 'Native Hawaiian or Other Pacific Islander',
                  label: 'Native Hawaiian or Other Pacific Islander',
                },
                { value: 'White', label: 'White' },
                { value: 'Other', label: 'Other' },
              ]}
              clearable
            />
          )}
        />
        <Controller
          name="languages"
          control={control}
          render={({ field }) => (
            <MultiSelect
              {...field}
              label="Languages"
              data={LANGUAGES.map((language) => ({
                value: language,
                label: language,
              }))}
              searchable
              clearable
              value={field.value || []}
            />
          )}
        />
        <Controller
          name="specialexperiences"
          control={control}
          render={({ field }) => (
            <MultiSelect
              {...field}
              label="Special Experiences"
              data={[{ value: 'LATER', label: 'LATER' }]}
              clearable
              value={field.value || []}
            />
          )}
        />

        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Home Address</Title>
          <TextInput label={'\u0405treet 1'} {...register('homestreet1')} />
          <TextInput label={'\u0405treet 2'} {...register('homestreet2')} />
          <Grid>
            <Grid.Col span={6}>
              <TextInput label="City" {...register('homecity')} />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="homestate"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="State"
                    data={STATES_ARRAY.map((state) => ({
                      value: state,
                      label: state,
                    }))}
                    clearable
                    searchable
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput label="ZIP" {...register('homezip')} />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="homecountry"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Country"
                    data={[{ value: 'US', label: 'United States' }]}
                    clearable
                    searchable
                  />
                )}
              />
            </Grid.Col>
          </Grid>
          <TextInput label="County" {...register('homecounty')} />
        </Paper>

        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Mailing Address</Title>
          <TextInput label={'\u0405treet 1'} {...register('mailingstreet1')} />
          <TextInput label={'\u0405treet 2'} {...register('mailingstreet2')} />
          <Grid>
            <Grid.Col span={6}>
              <TextInput label="City" {...register('mailingcity')} />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="mailingstate"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="State"
                    data={STATES_ARRAY.map((state) => ({
                      value: state,
                      label: state,
                    }))}
                    clearable
                    searchable
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput label="ZIP" {...register('mailingzip')} />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="mailingcountry"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Country"
                    data={[{ value: 'US', label: 'United States' }]}
                    clearable
                    searchable
                  />
                )}
              />
            </Grid.Col>
          </Grid>
          <TextInput label="County" {...register('mailingcounty')} />
        </Paper>
        <Button type="submit" mt="md">
          {memberId === 0 ? 'Add Member' : 'Save'}
        </Button>
      </form>
    </Container>
  );
};

export default MemberForm;
