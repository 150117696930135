import React, { useState, useEffect } from 'react';
import { Container, TextInput, Button, Group, Grid } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useMemberContext } from './MemberContext';
import { Member } from '../types/members.types';
import DarkModeToggle from './DarkModeToggle';
import useHttpClient from './hooks/useHttpClient';
import { Title } from '@mantine/core';
import { Table } from '@mantine/core';
import '../App.css';

const AdminPortal: React.FC = (props) => {
  const [members, setMembers] = useState<Member[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { setIsNewMember, setMemberId } = useMemberContext();
  const navigate = useNavigate();
  const httpClient = useHttpClient();

  const fetchMembers = async () => {
    try {
      const response = await httpClient.get<Member[]>('/api/members');
      setMembers(response.data);
    } catch (error) {
      console.error('Error fetching members', error);
    }
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredMembers = members
    .filter((member) =>
      `${member.firstname} ${member.lastname} ${member.npi}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase()),
    )
    .sort((a, b) => {
      if (a.lastname > b.lastname) return 1;
      if (a.lastname < b.lastname) return -1;
      if (a.firstname > b.firstname) return 1;
      if (a.firstname < b.firstname) return -1;
      return 0;
    });

  const handleMemberClick = async (memberId: number) => {
    setIsNewMember(false);
    setMemberId(memberId);
    navigate(`/member/${memberId}`);
  };

  const handleAddNewMember = () => {
    setIsNewMember(true);
    setMemberId(0);
    navigate('/member');
  };

  return (
    <Container size="xl">
      <Title style={{ textAlign: 'left', marginBottom: '2rem' }}>
        Admin Portal
      </Title>

      <Grid justify="space-between">
        <Grid.Col span="auto">
          <DarkModeToggle />
        </Grid.Col>
      </Grid>
      <Group mb="md">
        <TextInput
          placeholder="Search members"
          value={searchTerm}
          onChange={handleSearch}
        />
        <Button onClick={handleAddNewMember}>Add New Member</Button>
      </Group>

      <Group>
        <Table className="ryze-list table-striped table-highlight">
          <thead style={{ textAlign: 'left' }}>
            <tr>
              <th>Name</th>
              <th>NPI</th>
            </tr>
          </thead>
          <tbody>
            {filteredMembers.map((member) => (
              <tr key={member.id} onClick={() => handleMemberClick(member.id)}>
                <td>{`${member.firstname ?? ''} ${member.lastname ?? ''}`}</td>
                <td>{member.npi}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Group>
    </Container>
  );
};

export default AdminPortal;
