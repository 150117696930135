export interface Plan {
  id: string;
  name: string;
  inn: string[];
  oon: string[];
}

export const PLAN_SERVICES = [
  'Plan Coinsurance',
  'Deductible (Individual / Family)',
  'OOP Max (Individual / Family)',
  'Preventive Health Services (ACA)',
  'Primary Care Office Visits',
  'Mental Health/Substance Abuse Services Office',
  'Specialist Office Visits',
  'Telemedicine',
  'Urgent Care',
  'Chiropractic Office Visits',
  'Imaging and Lab performed during an office visit',
  'Emergency Room',
  'Inpatient Hospital',
  'Outpatient Hospital',
  'Ambulatory Surgery Center',
  'Inpatient Mental Health/Substance Use Facilities',
  'Outpatient Mental Health/Substance Use Facilities',
  'Independent Lab (non-hospital)',
  'Independent Imaging (non-hospital)',
  'Ambulance Services',
  'PT/OT/ST Services',
  'Durable Medical Equipment',
  'Other Covered Services Not Listed',
];

export const PLANS_STATIC: Plan[] = [
  {
    id: '1',
    name: 'Option 1 $1,000 Copay Plan',
    inn: [
      'Plan Pays 80%',
      '$1,000 / $3,000',
      '$2,000 / $4,000',
      'Plan Pays 100%',
      '$20 copay',
      '$20 copay',
      '$40 copay',
      '$0 copay',
      '$30 copay',
      '$20 copay',
      '20% after Deductible',
      '$250 copay',
      '20% after Deductible',
      '20% after Deductible',
      '20% after Deductible',
      '20% after Deductible',
      '20% after Deductible',
      '20% after Deductible',
      '20% after Deductible',
      '20% after Deductible',
      '20% after Deductible',
      '20% after Deductible',
    ],
    oon: [
      'Plan Pays 50%',
      '$2,000 / $4,000',
      '$6,000 / $12,000',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '$0 copay',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '$250 copay',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
    ],
  },
  {
    id: '2',
    name: 'Option 2 $3,000 Copay Plan',
    inn: [
      'Plan Pays 80%',
      '$3,000 / $6,000',
      '$4,500 / $9,000',
      'Plan Pays 100%',
      '$30 copay',
      '$30 copay',
      '$60 copay',
      '$0 copay',
      '$45 copay',
      '$30 copay',
      '20% after Deductible',
      '$250 copay',
      '20% after Deductible',
      '20% after Deductible',
      '20% after Deductible',
      '20% after Deductible',
      '20% after Deductible',
      '20% after Deductible',
      '20% after Deductible',
      '20% after Deductible',
      '20% after Deductible',
      '20% after Deductible',
    ],
    oon: [
      'Plan Pays 50%',
      '$6,000 / $12,000',
      '$9,000 / $18,000',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '$0 copay',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '?',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
    ],
  },
  {
    id: '3',
    name: 'Option 3 $5,000 Copay Plan',
    inn: [
      'Plan Pays 80%',
      '$5,000 / $10,000',
      '$7,500 / $15,000',
      'Plan Pays 100%',
      '$30 copay',
      '$30 copay',
      '$60 copay',
      '$0 copay',
      '$45 copay',
      '$30 copay',
      '20% after Deductible',
      '100% after Deductible',
      '20% after Deductible',
      '20% after Deductible',
      '20% after Deductible',
      '20% after Deductible',
      '20% after Deductible',
      '20% after Deductible',
      '20% after Deductible',
      '20% after Deductible',
      '20% after Deductible',
      '20% after Deductible',
    ],
    oon: [
      'Plan Pays 50%',
      '$10,000 / $12,000',
      '$15,000 / $18,000',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '$0 copay',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
    ],
  },
  {
    id: '4',
    name: 'Option 4 $3,500 HDHP Plan',
    inn: [
      'Plan Pays 100%',
      '$3,500 / $7,000',
      '$7,000 / $16,100',
      'Plan Pays 100%',
      '100% after Deductible',
      '100% after Deductible',
      '100% after Deductible',
      '100% after Deductible',
      '100% after Deductible',
      '100% after Deductible',
      '100% after Deductible',
      '',
      '100% after Deductible',
      '100% after Deductible',
      '100% after Deductible',
      '100% after Deductible',
      '100% after Deductible',
      '100% after Deductible',
      '100% after Deductible',
      '100% after Deductible',
      '100% after Deductible',
      '100% after Deductible',
    ],
    oon: [
      'Plan Pays 50%',
      '$7,000 / $14,000',
      '$13,500 / $27,000',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
    ],
  },
  {
    id: '5',
    name: 'Option 5 $6,000 HDHP Plan',
    inn: [
      'Plan Pays 100%',
      '$6,000 / $12,000',
      '$8,000 / $16,000',
      'Plan Pays 100%',
      '100% after Deductible',
      '100% after Deductible',
      '100% after Deductible',
      '100% after Deductible',
      '100% after Deductible',
      '100% after Deductible',
      '100% after Deductible',
      '',
      '100% after Deductible',
      '100% after Deductible',
      '100% after Deductible',
      '100% after Deductible',
      '100% after Deductible',
      '100% after Deductible',
      '100% after Deductible',
      '100% after Deductible',
      '100% after Deductible',
      '100% after Deductible',
    ],
    oon: [
      'Plan Pays 50%',
      '$13,500 / $27,000',
      '$13,500 / $27,000',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
      '50% after Deductible',
    ],
  },
];
