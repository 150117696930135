export const plans = {
  "Plan 1": {
    "INN": {
      "Premium - Individual": 854,
      "Premium - Individual + Spouse": 1793.4,
      "Premium - Individual + 1 Child": 1451.8,
      "Premium - Family": 2305.8,
      "Plan Coinsurance": "Plan Pays 80%\u200b",
      "Deductible": {
        "Individual": 1000,
        "Family": 3000
      },
      "OOP Max": {
        "Individual": 2000,
        "Family": 4000
      },
      "Preventive Health Services (ACA)": "Plan Pays 100%",
      "Primary Care Office Visits": "$20 copay",
      "Mental Health/Substance Abuse Services Office": "$20 copay",
      "Specialist Office Visits": "$40 copay",
      "Telemedicine": "$0 copay",
      "Urgent Care": "$30 copay",
      "Chiropractic Office Visits": "$20 copay",
      "Imaging and Lab performed during an office visit, not including advanced imaging such as MRIs, CT scans, etc.": "Plan pays 80% after deductible, 100% after OOP Max",
      "Emergency Room": 250,
      "Inpatient Hospital": "Plan pays 80% after deductible, 100% after OOP Max",
      "Outpatient Hospital": "Plan pays 80% after deductible, 100% after OOP Max",
      "Ambulatory Surgery Center": "Plan pays 80% after deductible, 100% after OOP Max",
      "Inpatient Mental Health/Substance Use Facilities": "Plan pays 80% after deductible, 100% after OOP Max",
      "Outpatient Mental Health/Substance Use Facilities": "Plan pays 80% after deductible, 100% after OOP Max",
      "Independent Lab (non-hospital)": "Plan pays 80% after deductible, 100% after OOP Max",
      "Independent Imaging (non-hospital)": "Plan pays 80% after deductible, 100% after OOP Max",
      "Ambulance Services": "Plan pays 80% only",
      "PT/OT/ST Services": "Plan pays 80% after deductible, 100% after OOP Max",
      "Durable Medical Equipment": "Plan pays 80% after deductible, 100% after OOP Max",
      "Other Covered Services Not Listed": "Plan pays 80% after deductible, 100% after OOP Max",
      "Preventative": "Plan Pays 100%",
      "Generic (30 day | 31-90 day)": 0,
      "Preferred Brand (30 day | 31-90 day)": 30,
      "Non-Preferred Brand (30 day | 31-90 day)": 60,
      "Compound (30 day | 31-90 day)": 60,
      "Specialty (limited to 30 days supply)": 150,
    },
    "OON": {
      "Premium - Individual": null,
      "Premium - Individual + Spouse": null,
      "Premium - Individual + 1 Child": null,
      "Premium - Family": null,
      "Plan Coinsurance": "Plan Pays 50%\u200b",
      "Deductible": {
        "Individual": 2000,
        "Family": 4000
      },
      "OOP Max": {
        "Individual": 6000,
        "Family": 12000
      },
      "Preventive Health Services (ACA)": "50% after Deductible\u200b",
      "Primary Care Office Visits": "50% after Deductible\u200b",
      "Mental Health/Substance Abuse Services Office": "50% after Deductible\u200b",
      "Specialist Office Visits": "50% after Deductible\u200b",
      "Telemedicine": 0,
      "Urgent Care": "50% after Deductible\u200b",
      "Chiropractic Office Visits": "50% after Deductible\u200b",
      "Imaging and Lab performed during an office visit, not including advanced imaging such as MRIs, CT scans, etc.": "50% after Deductible\u200b",
      "Emergency Room": null,
      "Inpatient Hospital": "50% after Deductible\u200b",
      "Outpatient Hospital": "50% after Deductible\u200b",
      "Ambulatory Surgery Center": "50% after Deductible\u200b",
      "Inpatient Mental Health/Substance Use Facilities": "50% after Deductible\u200b",
      "Outpatient Mental Health/Substance Use Facilities": "50% after Deductible\u200b",
      "Independent Lab (non-hospital)": "50% after Deductible\u200b",
      "Independent Imaging (non-hospital)": "50% after Deductible\u200b",
      "Ambulance Services": null,
      "PT/OT/ST Services": "50% after Deductible\u200b",
      "Durable Medical Equipment": "50% after Deductible\u200b",
      "Other Covered Services Not Listed": "50% after Deductible\u200b",
      "Preventative": "No Coverage",
      "Generic (30 day | 31-90 day)": null,
      "Preferred Brand (30 day | 31-90 day)": null,
      "Non-Preferred Brand (30 day | 31-90 day)": null,
      "Compound (30 day | 31-90 day)": null,
      "Specialty (limited to 30 days supply)": null,
    }
  },
  "Plan 2": {
    "INN": {
      "Premium - Individual": 739,
      "Premium - Individual + Spouse": 1551.9,
      "Premium - Individual + 1 Child": 1256.3,
      "Premium - Family": 1995.3,
      "Plan Coinsurance": "Plan Pays 80%\u200b",
      "Deductible": {
        "Individual": 3000,
        "Family": 6000
      },
      "OOP Max": {
        "Individual": 4500,
        "Family": 9000
      },
      "Preventive Health Services (ACA)": "Plan Pays 100%",
      "Primary Care Office Visits": "$30 copay",
      "Mental Health/Substance Abuse Services Office": "$30 copay",
      "Specialist Office Visits": "$60 copay",
      "Telemedicine": "$0 copay",
      "Urgent Care": "$45 copay",
      "Chiropractic Office Visits": "$30 copay",
      "Imaging and Lab performed during an office visit, not including advanced imaging such as MRIs, CT scans, etc.": "Plan pays 80% after deductible, 100% after OOP Max",
      "Emergency Room": 250,
      "Inpatient Hospital": "Plan pays 80% after deductible, 100% after OOP Max",
      "Outpatient Hospital": "Plan pays 80% after deductible, 100% after OOP Max",
      "Ambulatory Surgery Center": "Plan pays 80% after deductible, 100% after OOP Max",
      "Inpatient Mental Health/Substance Use Facilities": "Plan pays 80% after deductible, 100% after OOP Max",
      "Outpatient Mental Health/Substance Use Facilities": "Plan pays 80% after deductible, 100% after OOP Max",
      "Independent Lab (non-hospital)": "Plan pays 80% after deductible, 100% after OOP Max",
      "Independent Imaging (non-hospital)": "Plan pays 80% after deductible, 100% after OOP Max",
      "Ambulance Services": "Plan pays 80% only",
      "PT/OT/ST Services": "Plan pays 80% after deductible, 100% after OOP Max",
      "Durable Medical Equipment": "Plan pays 80% after deductible, 100% after OOP Max",
      "Other Covered Services Not Listed": "Plan pays 80% after deductible, 100% after OOP Max",
      "Preventative": "Plan Pays 100%",
      "Generic (30 day | 31-90 day)": 0,
      "Preferred Brand (30 day | 31-90 day)": 30,
      "Non-Preferred Brand (30 day | 31-90 day)": 60,
      "Compound (30 day | 31-90 day)": 60,
      "Specialty (limited to 30 days supply)": 150,
    },
    "OON": {
      "Premium - Individual": null,
      "Premium - Individual + Spouse": null,
      "Premium - Individual + 1 Child": null,
      "Premium - Family": null,
      "Plan Coinsurance": "Plan Pays 50%\u200b",
      "Deductible": {
        "Individual": 6000,
        "Family": 12000
      },
      "OOP Max": {
        "Individual": 9000,
        "Family": 18000
      },
      "Preventive Health Services (ACA)": "50% after Deductible\u200b",
      "Primary Care Office Visits": "50% after Deductible\u200b",
      "Mental Health/Substance Abuse Services Office": "50% after Deductible\u200b",
      "Specialist Office Visits": "50% after Deductible\u200b",
      "Telemedicine": 0,
      "Urgent Care": "50% after Deductible\u200b",
      "Chiropractic Office Visits": "50% after Deductible\u200b",
      "Imaging and Lab performed during an office visit, not including advanced imaging such as MRIs, CT scans, etc.": "50% after Deductible\u200b",
      "Emergency Room": null,
      "Inpatient Hospital": "50% after Deductible\u200b",
      "Outpatient Hospital": "50% after Deductible\u200b",
      "Ambulatory Surgery Center": "50% after Deductible\u200b",
      "Inpatient Mental Health/Substance Use Facilities": "50% after Deductible\u200b",
      "Outpatient Mental Health/Substance Use Facilities": "50% after Deductible\u200b",
      "Independent Lab (non-hospital)": "50% after Deductible\u200b",
      "Independent Imaging (non-hospital)": "50% after Deductible\u200b",
      "Ambulance Services": null,
      "PT/OT/ST Services": "50% after Deductible\u200b",
      "Durable Medical Equipment": "50% after Deductible\u200b",
      "Other Covered Services Not Listed": "50% after Deductible\u200b",
      "Preventative": "No Coverage",
      "Generic (30 day | 31-90 day)": null,
      "Preferred Brand (30 day | 31-90 day)": null,
      "Non-Preferred Brand (30 day | 31-90 day)": null,
      "Compound (30 day | 31-90 day)": null,
      "Specialty (limited to 30 days supply)": null,
    }
  },
  "Plan 3": {
    "INN": {
      "Premium - Individual": 656,
      "Premium - Individual + Spouse": 1377.6,
      "Premium - Individual + 1 Child": 1115.2,
      "Premium - Family": 1771.2,
      "Plan Coinsurance": "Plan Pays 80%\u200b",
      "Deductible": {
        "Individual": 5000,
        "Family": 10000
      },
      "OOP Max": {
        "Individual": 7500,
        "Family": 15000
      },
      "Preventive Health Services (ACA)": "Plan Pays 100%",
      "Primary Care Office Visits": "$30 copay",
      "Mental Health/Substance Abuse Services Office": "$30 copay",
      "Specialist Office Visits": "$60 copay",
      "Telemedicine": "$0 copay",
      "Urgent Care": "$45 copay",
      "Chiropractic Office Visits": "$30 copay",
      "Imaging and Lab performed during an office visit, not including advanced imaging such as MRIs, CT scans, etc.": "Plan pays 80% after deductible, 100% after OOP Max",
      "Emergency Room": 250,
      "Inpatient Hospital": "Plan pays 80% after deductible, 100% after OOP Max",
      "Outpatient Hospital": "Plan pays 80% after deductible, 100% after OOP Max",
      "Ambulatory Surgery Center": "Plan pays 80% after deductible, 100% after OOP Max",
      "Inpatient Mental Health/Substance Use Facilities": "Plan pays 80% after deductible, 100% after OOP Max",
      "Outpatient Mental Health/Substance Use Facilities": "Plan pays 80% after deductible, 100% after OOP Max",
      "Independent Lab (non-hospital)": "Plan pays 80% after deductible, 100% after OOP Max",
      "Independent Imaging (non-hospital)": "Plan pays 80% after deductible, 100% after OOP Max",
      "Ambulance Services": "Plan pays 80% only",
      "PT/OT/ST Services": "Plan pays 80% after deductible, 100% after OOP Max",
      "Durable Medical Equipment": "Plan pays 80% after deductible, 100% after OOP Max",
      "Other Covered Services Not Listed": "Plan pays 80% after deductible, 100% after OOP Max",
      "Preventative": "Plan Pays 100%",
      "Generic (30 day | 31-90 day)": 0,
      "Preferred Brand (30 day | 31-90 day)": 30,
      "Non-Preferred Brand (30 day | 31-90 day)": 60,
      "Compound (30 day | 31-90 day)": 60,
      "Specialty (limited to 30 days supply)": 150,
    },
    "OON": {
      "Premium - Individual": null,
      "Premium - Individual + Spouse": null,
      "Premium - Individual + 1 Child": null,
      "Premium - Family": null,
      "Plan Coinsurance": "Plan Pays 50%\u200b",
      "OON": {
        "Deductible": {
          "Individual": 10000,
          "Family": 12000
        },
        "OOP Max": {
          "Individual": 15000,
          "Family": 18000
        }
      },
      "Preventive Health Services (ACA)": "50% after Deductible\u200b",
      "Primary Care Office Visits": "50% after Deductible\u200b",
      "Mental Health/Substance Abuse Services Office": "50% after Deductible\u200b",
      "Specialist Office Visits": "50% after Deductible\u200b",
      "Telemedicine": 0,
      "Urgent Care": "50% after Deductible\u200b",
      "Chiropractic Office Visits": "50% after Deductible\u200b",
      "Imaging and Lab performed during an office visit, not including advanced imaging such as MRIs, CT scans, etc.": "50% after Deductible\u200b",
      "Emergency Room": null,
      "Inpatient Hospital": "50% after Deductible\u200b",
      "Outpatient Hospital": "50% after Deductible\u200b",
      "Ambulatory Surgery Center": "50% after Deductible\u200b",
      "Inpatient Mental Health/Substance Use Facilities": "50% after Deductible\u200b",
      "Outpatient Mental Health/Substance Use Facilities": "50% after Deductible\u200b",
      "Independent Lab (non-hospital)": "50% after Deductible\u200b",
      "Independent Imaging (non-hospital)": "50% after Deductible\u200b",
      "Ambulance Services": null,
      "PT/OT/ST Services": "50% after Deductible\u200b",
      "Durable Medical Equipment": "50% after Deductible\u200b",
      "Other Covered Services Not Listed": "50% after Deductible\u200b",
      "Preventative": "No Coverage",
      "Generic (30 day | 31-90 day)": null,
      "Preferred Brand (30 day | 31-90 day)": null,
      "Non-Preferred Brand (30 day | 31-90 day)": null,
      "Compound (30 day | 31-90 day)": null,
      "Specialty (limited to 30 days supply)": null,
    }
  },
  "Plan 4": {
    "INN": {
      "Premium - Individual": 799,
      "Premium - Individual + Spouse": 1650,
      "Premium - Individual + 1 Child": 1387,
      "Premium - Family": 2069,
      "Plan Coinsurance": 0.7,
      "Deductible": {
        "Individual": 3500,
        "Family": 7000
      },
      "OOP Max": {
        "Individual": 7000,
        "Family": 16100
      },
      "Preventive Health Services (ACA)": 0.3,
      "Primary Care Office Visits": 20,
      "Mental Health/Substance Abuse Services Office": 0.3,
      "Specialist Office Visits": 60,
      "Telemedicine": null,
      "Urgent Care": 0.3,
      "Chiropractic Office Visits": 0.3,
      "Imaging and Lab performed during an office visit, not including advanced imaging such as MRIs, CT scans, etc.": 0.3,
      "Emergency Room": "?",
      "Inpatient Hospital": 0.3,
      "Outpatient Hospital": null,
      "Ambulatory Surgery Center": null,
      "Inpatient Mental Health/Substance Use Facilities": 0.3,
      "Outpatient Mental Health/Substance Use Facilities": null,
      "Independent Lab (non-hospital)": null,
      "Independent Imaging (non-hospital)": null,
      "Ambulance Services": null,
      "PT/OT/ST Services": null,
      "Durable Medical Equipment": null,
      "Other Covered Services Not Listed": null,
      "Preventative": null,
      "Generic (30 day | 31-90 day)": null,
      "Preferred Brand (30 day | 31-90 day)": null,
      "Non-Preferred Brand (30 day | 31-90 day)": null,
      "Compound (30 day | 31-90 day)": null,
      "Specialty (limited to 30 days supply)": null,
    },
    "OON": {
      "Premium - Individual": null,
      "Premium - Individual + Spouse": null,
      "Premium - Individual + 1 Child": null,
      "Premium - Family": null,
      "Plan Coinsurance": 0.5,
      "Deductible": {
        "Individual": 7000,
        "Family": 14000
      },
      "OOP Max": {
        "Individual": 13500,
        "Family": 27000
      },
      "Preventive Health Services (ACA)": 0.5,
      "Primary Care Office Visits": null,
      "Mental Health/Substance Abuse Services Office": 0.5,
      "Specialist Office Visits": null,
      "Telemedicine": null,
      "Urgent Care": 0.5,
      "Chiropractic Office Visits": 0.5,
      "Imaging and Lab performed during an office visit, not including advanced imaging such as MRIs, CT scans, etc.": 0.5,
      "Emergency Room": null,
      "Inpatient Hospital": 0.5,
      "Outpatient Hospital": null,
      "Ambulatory Surgery Center": null,
      "Inpatient Mental Health/Substance Use Facilities": 0.5,
      "Outpatient Mental Health/Substance Use Facilities": null,
      "Independent Lab (non-hospital)": null,
      "Independent Imaging (non-hospital)": null,
      "Ambulance Services": null,
      "PT/OT/ST Services": null,
      "Durable Medical Equipment": null,
      "Other Covered Services Not Listed": null,
      "Preventative": null,
      "Generic (30 day | 31-90 day)": null,
      "Preferred Brand (30 day | 31-90 day)": null,
      "Non-Preferred Brand (30 day | 31-90 day)": null,
      "Compound (30 day | 31-90 day)": null,
      "Specialty (limited to 30 days supply)": null,
    }
  },
  "Plan 5": {
    "INN": {
      "Premium - Individual": 723,
      "Premium - Individual + Spouse": 1419,
      "Premium - Individual + 1 Child": 1280,
      "Premium - Family": 2115,
      "Plan Coinsurance": "100% after deductible",
      "Deductible": {
        "Individual": 6000,
        "Family": 12000
      },
      "OOP Max": {
        "Individual": 8000,
        "Family": 16000
      },
      "Preventive Health Services (ACA)": null,
      "Primary Care Office Visits": 50,
      "Mental Health/Substance Abuse Services Office": null,
      "Specialist Office Visits": 100,
      "Telemedicine": null,
      "Urgent Care": "100% (after ded)",
      "Chiropractic Office Visits": 1,
      "Imaging and Lab performed during an office visit, not including advanced imaging such as MRIs, CT scans, etc.": 1,
      "Emergency Room": 100,
      "Inpatient Hospital": 100,
      "Outpatient Hospital": 100,
      "Ambulatory Surgery Center": 100,
      "Inpatient Mental Health/Substance Use Facilities": 100,
      "Outpatient Mental Health/Substance Use Facilities": 100,
      "Independent Lab (non-hospital)": null,
      "Independent Imaging (non-hospital)": null,
      "Ambulance Services": null,
      "PT/OT/ST Services": null,
      "Durable Medical Equipment": null,
      "Other Covered Services Not Listed": null,
      "Preventative": null,
      "Generic (30 day | 31-90 day)": null,
      "Preferred Brand (30 day | 31-90 day)": null,
      "Non-Preferred Brand (30 day | 31-90 day)": null,
      "Compound (30 day | 31-90 day)": null,
      "Specialty (limited to 30 days supply)": null,
    },
    "OON": {
      "Premium - Individual": null,
      "Premium - Individual + Spouse": null,
      "Premium - Individual + 1 Child": null,
      "Premium - Family": null,
      "Plan Coinsurance": null,
      "Deductible": {
        "Individual": 13500,
        "Family": 27000
      },
      "OOP Max": {
        "Individual": 13500,
        "Family": 27000
      },
      "Preventive Health Services (ACA)": null,
      "Primary Care Office Visits": null,
      "Mental Health/Substance Abuse Services Office": null,
      "Specialist Office Visits": null,
      "Telemedicine": null,
      "Urgent Care": null,
      "Chiropractic Office Visits": null,
      "Imaging and Lab performed during an office visit, not including advanced imaging such as MRIs, CT scans, etc.": null,
      "Emergency Room": null,
      "Inpatient Hospital": null,
      "Outpatient Hospital": null,
      "Ambulatory Surgery Center": null,
      "Inpatient Mental Health/Substance Use Facilities": null,
      "Outpatient Mental Health/Substance Use Facilities": null,
      "Independent Lab (non-hospital)": null,
      "Independent Imaging (non-hospital)": null,
      "Ambulance Services": null,
      "PT/OT/ST Services": null,
      "Durable Medical Equipment": null,
      "Other Covered Services Not Listed": null,
      "Preventative": null,
      "Generic (30 day | 31-90 day)": null,
      "Preferred Brand (30 day | 31-90 day)": null,
      "Non-Preferred Brand (30 day | 31-90 day)": null,
      "Compound (30 day | 31-90 day)": null,
      "Specialty (limited to 30 days supply)": null,
    }
  }
}
