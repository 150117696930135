import { useState, useEffect } from 'react';
import {
  UnstyledButton,
  Tooltip,
  Text,
  rem,
  Avatar,
  Group,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import classes from './DoubleNavbar.module.css';
import { useAuth } from './AuthProvider';
import { type } from 'os';
import { User } from 'tabler-icons-react';

export function DoubleNavbar({ location, mainMenu = [], subMenu }) {
  // main menu
  const f = mainMenu.filter((menu) => menu.path == location.pathname)[0];
  const [active, setActive] = useState(f?.label || mainMenu?.[0]?.label);

  const auth = useAuth();
  const privileges = auth.getPrivileges();
  const username = privileges[0].username;
  const type = privileges[0].type
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase());

  const closed = true;

  const mainLinks = mainMenu.map((link) => (
    <Tooltip
      label={link.label}
      position="right"
      withArrow
      transitionProps={{ duration: 0 }}
      key={link.label}
    >
      <UnstyledButton
        onClick={() => {
          if (link.path === '/logout') {
            logoutAuth();
          } else {
            navigate(link.path || '/');
            setActive(link.label);
          }
        }}
        className={
          subMenu.menu?.length ? classes.mainLinkClosed : classes.mainLink
        }
        data-active={link.label === active || undefined}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <link.icon
          style={{ width: rem(30), height: rem(30), marginRight: rem(12) }}
          stroke={1.5}
        />
        <Text size="sm">{link.label}</Text>
      </UnstyledButton>
    </Tooltip>
  ));

  // sub menu
  const { menu, allowSwitchingBetweenPages = true } = subMenu;
  const hash = location.hash.substring(1);
  const [activeLink, setActiveLink] = useState(hash || menu?.[0]?.key);
  const navigate = useNavigate();
  const { logoutAuth } = useAuth();
  useEffect(() => {
    setActiveLink(hash);
  }, [hash, location.hash]);

  const links = menu?.map((link) => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      className={
        allowSwitchingBetweenPages ? classes.link : classes.linkDisabled
      }
      data-active={
        activeLink === link.key ||
        (!activeLink && link.key === menu?.[0]?.key) ||
        undefined
      }
      href="#"
      onClick={(event) => {
        event.preventDefault();
        if (allowSwitchingBetweenPages) {
          setActiveLink(link.key);
          window.location.hash = link.key;
        }
      }}
      key={link.key}
    >
      {link.value}
    </a>
  ));

  return (
    <nav className={classes.navbar}>
      <div className={classes.wrapper}>
        <div
          className={subMenu.menu?.length ? classes.asideClosed : classes.aside}
        >
          {mainLinks}
        </div>
        {menu && <div className={classes.main}>{links}</div>}
      </div>
      <div className="classes.userInfo">
        {mainMenu?.length && (
          <Group className={classes.userInfo} align="center">
            <Avatar radius="xl">
              <User size={24} />
            </Avatar>
            <div>
              <Text size="sm">{type}</Text>
              <Text size="xs" color="dimmed">
                {username}
              </Text>
            </div>
          </Group>
        )}
      </div>
    </nav>
  );
}
