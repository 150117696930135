import { useState, useEffect, useMemo } from 'react';
import {
  Grid,
  Text,
  Group,
  Button,
  Paper,
  Container,
  Title,
  Avatar,
  Skeleton,
  Loader,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';
import useHttpClient from './hooks/useHttpClient';
import { User } from 'tabler-icons-react';
import { useMantineColorScheme, useComputedColorScheme } from '@mantine/core';
import { Sun, MoonStars } from 'tabler-icons-react';
import { useAuth } from './hooks/useAuth';
import { useAuth as useAuth2 } from './AuthProvider';

export default function Settings() {
  const { setColorScheme } = useMantineColorScheme();
  const computedColorScheme = useComputedColorScheme('light');
  const toggleColorScheme = () => {
    setColorScheme(computedColorScheme === 'dark' ? 'light' : 'dark');
  };

  const httpClient = useHttpClient();
  const navigate = useNavigate();
  const { organizationId, subscriberMemberId, wecareMemberId } = useAuth();
  const [userInfo, setUserInfo] = useState<any>();
  const [loading, setIsloading] = useState<boolean>(false);

  const auth = useAuth2();
  const privileges = auth.getPrivileges();
  const username = privileges[0].username;
  const types = (privileges || [])
    .map((priv) => priv.type)
    .join(',')
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase());

  const fetchUserInformation = async () => {
    console.log('subscriberMemberId', subscriberMemberId);
    setIsloading(true);
    try {
      if (subscriberMemberId) {
        const { data } = await httpClient.get(
          `/api/subscriber/${subscriberMemberId}`,
        );
        setUserInfo(data);
      }
      if (organizationId) {
        const { data } = await httpClient.get(`/api/organization`);
        setUserInfo((setUserInfo) => {
          return { ...data, ...setUserInfo };
        });
      }
    } catch (error) {
      console.error('failed to fetch user information', error);
    } finally {
      setIsloading(false);
    }
  };

  useMemo(() => {
    fetchUserInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriberMemberId, organizationId]);

  return (
    <Container size="xl">
      <Title style={{ textAlign: 'left', marginBottom: '2rem' }}>
        Settings
      </Title>

      {/* <pre>{JSON.stringify(userInfo,null,2)}</pre> */}
      <Paper withBorder shadow="md" p={30} radius="md">
        <Avatar size={120} radius={120} mx="auto">
          <User size={100} />
        </Avatar>

        {userInfo && (
          <Text ta="center" fz="lg" fw={500} mt="md">
            {organizationId > 0 ? (
              <h3 style={{ marginBottom: 0 }}>{userInfo?.name}</h3>
            ) : (
              ''
            )}
            {subscriberMemberId > 0
              ? (userInfo?.firstname || '-') + ' ' + (userInfo?.lastname || '-')
              : organizationId > 0
                ? (userInfo?.adminfirstname || '-') +
                  ' ' +
                  (userInfo?.adminlastname || '-')
                : ''}
          </Text>
        )}
        <Text ta="center" c="dimmed" fz="sm" mt={'md'}>
          {username}
          <br />
          {types}
        </Text>

        {userInfo && (
          <Container
            style={{ marginTop: '10px', marginBottom: '20px', width: '100%' }}
          >
            <Grid style={{ textAlign: 'center' }}>
              <Grid.Col span={4}>
                <Text size="xs">NPI</Text>
                <Skeleton visible={loading}>
                  <Text>{userInfo?.npi || '-'}</Text>
                </Skeleton>
              </Grid.Col>
              {/* {<Grid.Col span={4}>
              <Text size="xs">License Number</Text>
              <Skeleton visible={loading}>
                <Text>{userInfo?.licensenumber || '-'}</Text>
              </Skeleton>
            </Grid.Col> } */}
              <Grid.Col span={4}>
                <Text size="xs">License Type</Text>
                <Skeleton visible={loading}>
                  <Text>{userInfo?.type || '-'}</Text>
                </Skeleton>
              </Grid.Col>
              {userInfo?.enrollmentstatus && (
                <Grid.Col span={4}>
                  <Text size="xs">Enrollment Status</Text>
                  <Skeleton visible={loading}>
                    <Text>{userInfo?.enrollmentstatus || '-'}</Text>
                  </Skeleton>
                </Grid.Col>
              )}
            </Grid>
          </Container>
        )}
        <Group justify="center" mt={'xl'}>
          <Button onClick={() => navigate('/settings/reset-password')}>
            Reset Password
          </Button>{' '}
          <Button onClick={toggleColorScheme}>
            {computedColorScheme === 'dark' ? (
              <Sun style={{ width: 20, height: 20 }} />
            ) : (
              <MoonStars style={{ width: 20, height: 20 }} />
            )}
            Dark Mode
          </Button>
        </Group>
      </Paper>
    </Container>
  );
}
