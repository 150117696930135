import React, { useState } from 'react';
import { useAuth } from '../AuthProvider';
import {
  TextInput,
  Button,
  Paper,
  Text,
  Anchor,
  Container,
  Title,
  Center,
} from '@mantine/core';
import { Link } from 'react-router-dom';
import WecareLogo from '../WecareLogo';

export default function WecareLogin() {
  const [input, setInput] = useState({
    username: '',
    password: '',
  });
  const auth = useAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.username !== '' && input.password !== '') {
      auth.loginAuth(input, 'wecare');
      return;
    }
    alert('Please provide a valid input');
  };

  return (
    <Container size={420} mt={100}>
      <Paper withBorder shadow="md" p={30} radius="md" mt="lg">
        <form onSubmit={handleSubmit}>
          <Center mb="lg">
            <WecareLogo />
          </Center>
          <Center mb="lg">
            <Title order={2}>Wecare Login</Title>
          </Center>
          <TextInput
            label="Username (email)"
            placeholder="Enter your username"
            value={input.username}
            onChange={(e) => setInput({ ...input, username: e.target.value })}
            required
            mb="md"
          />
          <TextInput
            label="Password"
            placeholder="Enter your password"
            type="password"
            value={input.password}
            onChange={(e) => setInput({ ...input, password: e.target.value })}
            required
            mb="md"
          />
          <Center mt="md">
            <Button type="submit">Login</Button>
          </Center>
          <Center mt="md">
            <Text size="sm">
              Forgot your password?{' '}
              <Anchor component={Link} to="/reset-password">
                Reset here
              </Anchor>
            </Text>
          </Center>
        </form>
      </Paper>
    </Container>
  );
}
