import React, { useState, useEffect } from 'react';
import { Tabs, Button, Group, Title } from '@mantine/core';
import PersonalInformationTab from './PersonalInformationTab';
import FamilyMemberManagementTab, {
  familyMgmtValidation,
} from './FamilyManagementTab';
import { useInsuranceMemberContext } from './InsuranceMemberContext';
import { notifications } from '@mantine/notifications';
import PlanSelectionTab from './PlanSelectionTab';
import PaymentInformationTab from './PaymentInformationTab';
import CompleteRegistrationModal from './CompleteMemberRegistration';
import { Container } from '@mantine/core';
import { OrganizationProvider } from './OrganizationContext';
import { InsuranceMemberProvider } from './InsuranceMemberContext';
import { useLocation } from 'react-router-dom';
import { subMenuList } from '../menu.config';
import {
  useStepper,
  NextStepButton,
  PrevStepButton,
  CompleteStepButton,
  ButtonContainer,
  IndependentPhysRegStepper,
  StepperProvider,
} from './RegistrationSteppers';
import { ModalsProvider, modals } from '@mantine/modals';
import {
  IconCircleCheck,
  IconRosetteDiscountCheckFilled,
} from '@tabler/icons-react';
import PrioritiesTab from './PrioritiesTab';

const InsuranceMemberPortal: React.FC = () => {
  const location = useLocation();
  const currentTab = location.hash.substring(1) || 'personal-info';
  const { enabled, markStepAsDone, subMenu } = useStepper();

  const { fetchMemberData, member } = useInsuranceMemberContext();
  const [modalOpened, setModalOpened] = useState(false);
  const [isFamilyFieldsComplete, setIsFamilyFieldsComplete] = useState(true); //temp true until implement check
  const [isPlanSelectionComplete, setIsPlanSelectionComplete] = useState(false);
  const [isPaymentInfoComplete, setIsPaymentInfoComplete] = useState(false);
  const [activeTab, setActiveTab] = useState<string>(currentTab);

  const currIndex = subMenu.findIndex((item) => item?.key === currentTab);

  const validateForm = (infoComplete) => {
    if (infoComplete) {
      markStepAsDone(activeTab);
      return true;
    } else {
      markStepAsDone(activeTab, false);
      return false;
    }
  };

  /**
   * Checks if all required personal information fields are complete.
   *
   * @returns `true` if all fields are complete, otherwise `false`.
   */
  const checkFieldsComplete = async () => {
    const requiredPersonalInfoFields = [
      'firstname',
      'lastname',
      'birthdate',
      'email',
      'street1',
      'city',
      'state',
      'zip',
      'county',
    ];
    const incompleteFields: string[] = [];
    const completeFields: string[] = [];

    const isPersonalInfoComplete = requiredPersonalInfoFields.every((field) => {
      const isFieldComplete =
        member &&
        member[field as keyof typeof member] &&
        member[field as keyof typeof member] !== '';
      if (isFieldComplete) {
        completeFields.push(field);
      } else {
        incompleteFields.push(field);
      }
      return isFieldComplete;
    });

    // Log both the complete and incomplete fields for debugging purposes
    console.log('Complete Fields:', completeFields);
    console.log('Incomplete Fields:', incompleteFields);

    if (currentTab === 'personal-info') {
      return validateForm(isPersonalInfoComplete);
    }
    if (currentTab === 'plan-selection') {
      return validateForm(isPlanSelectionComplete);
    }
    if (currentTab === 'payment-information') {
      return validateForm(isPaymentInfoComplete);
    }
    if (currentTab === 'family-member-management') {
      const done = await familyMgmtValidation();
      if (done) {
        markStepAsDone(activeTab);
      }
      return done;
    }
    return true;
  };

  const handleOpenModal = () => {
    if (validateForm(isPaymentInfoComplete)) {
      setModalOpened(true);
    } else {
      notifications.show({
        title: 'Incomplete Information',
        message: 'Please complete your payment information before proceeding.',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  const validationCallback = async () => {
    const hasPassedValidation = await checkFieldsComplete();
    if (hasPassedValidation) {
      setActiveTab(currentTab);
    }
    return hasPassedValidation;
  };

  useEffect(() => {
    setActiveTab(currentTab);
    if (member) {
      checkFieldsComplete();
    }
  }, [currentTab, member]);

  return (
    <>
      <ButtonContainer>
        <PrevStepButton enabled={enabled} />

        <IndependentPhysRegStepper />

        {currIndex < subMenu.length - 1 ? (
          <ModalsProvider>
            <NextStepButton
              enabled={enabled && activeTab !== 'priorities'}
              validationCallback={validationCallback}
            />
          </ModalsProvider>
        ) : (
          <CompleteStepButton
            enabled={false}
            // enabled={enabled}
            onClick={() => {
              handleOpenModal();
            }}
          />
        )}
      </ButtonContainer>

      <Title
        order={1}
        style={{
          textAlign: 'center',
        }}
      >
        Ryze Insurance Registration
      </Title>

      <CompleteRegistrationModal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
      />

      <Tabs defaultValue="personal-info" keepMounted={false} value={activeTab}>
        <Tabs.Panel value="personal-info">
          {member && (
            <PersonalInformationTab
              onFormSubmit={() => {
                fetchMemberData();
              }}
            />
          )}
        </Tabs.Panel>
        <Tabs.Panel value="family-member-management">
          {member && (
            <FamilyMemberManagementTab
              onFieldsCompleteChange={setIsFamilyFieldsComplete}
            />
          )}
        </Tabs.Panel>
        <Tabs.Panel value="priorities">
          {member && (
            <PrioritiesTab
              onFieldsCompleteChange={setIsPlanSelectionComplete}
            />
          )}
        </Tabs.Panel>
        {/* <Tabs.Panel value="plan-selection">
          {member && (
            <PlanSelectionTab
              onFieldsCompleteChange={setIsPlanSelectionComplete}
            />
          )}
        </Tabs.Panel>
        <Tabs.Panel value="payment-information">
          <PaymentInformationTab
            onFieldsCompleteChange={setIsPaymentInfoComplete}
          />
        </Tabs.Panel> */}
      </Tabs>
    </>
  );
};

const InsuranceMemberPortalWrapper: React.FC = () => {
  const subMenu = subMenuList.independentPhysician.menu;

  return (
    <OrganizationProvider>
      <InsuranceMemberProvider>
        <StepperProvider totalSteps={5} subMenu={subMenu}>
          <Container size="xl" style={{ marginTop: '2rem' }}>
            <InsuranceMemberPortal />
          </Container>
        </StepperProvider>
      </InsuranceMemberProvider>
    </OrganizationProvider>
  );
};

export default InsuranceMemberPortalWrapper;
