import { Image } from '@mantine/core';
import { useComputedColorScheme } from '@mantine/core';

const RyzeLogo: React.FC = () => {
  const computedColorScheme = useComputedColorScheme('light');

  return (
    <Image
      src={
        computedColorScheme === 'dark'
          ? `${process.env.PUBLIC_URL}/Logo_White.png`
          : `${process.env.PUBLIC_URL}/Logo_Full.png`
      }
      alt="Rzye Logo"
      style={{
        position: 'relative',
        padding: '16px 0px',
        width: '200px',
        display: 'flex',
        alignItems: 'center',
      }}
    />
  );
};

export default RyzeLogo;
