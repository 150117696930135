import React, { useEffect, useState, useCallback } from 'react';
import { Container, Tabs, Grid, Title, Button, Group } from '@mantine/core';
import EmployeeManagementTab from './EmployeeManagementTab';
import PracticeInfoTab from './PracticeInfoTab';
import PaymentInformationTab from './PaymentInformationTab';
import HomeIcon from '../HomeIcon';
import LogoutIcon from '../LogoutIcon';
import DarkModeToggle from '../DarkModeToggle';
import { OrganizationProvider } from './../InsurancePortal/OrganizationContext';
import { useLocation } from 'react-router-dom';
import {
  ButtonContainer,
  ClinicRegistrationStepper,
  CompleteStepButton,
  NextStepButton,
  PrevStepButton,
  StepperProvider,
  useStepper,
} from './RegistrationSteppers';
import { subMenuList } from '../menu.config';
import { ModalsProvider } from '@mantine/modals';
import CompleteRegistrationModal from './CompleteClinicRegistration';
import { InsuranceMemberProvider } from './InsuranceMemberContext';

const InsuranceAdminPortal: React.FC = () => {
  const location = useLocation();
  const currentTab = location.hash.substring(1) || 'practice-info';

  const { enabled, markStepAsDone, activeStep, subMenu } = useStepper();
  const [activeTab, setActiveTab] = useState<string>(currentTab);
  const [isPracticeInfoComplete, setIsPracticeInfoComplete] = useState(false);
  const [isPaymentInfoComplete, setIsPaymentInfoComplete] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);

  const checkFieldsComplete = async () => {
    const validateForm = (infoComplete) => {
      if (infoComplete) {
        markStepAsDone(activeTab);
        return true;
      } else {
        markStepAsDone(activeTab, false);
        return false;
      }
    };

    if (activeTab === 'employees') {
      markStepAsDone(activeTab);
    }
    if (activeTab === 'practice-info') {
      return validateForm(isPracticeInfoComplete);
    }
    if (activeTab === 'payment-information') {
      return validateForm(isPaymentInfoComplete);
    }
    return true;
  };

  useEffect(() => {
    setActiveTab(currentTab);
  }, [currentTab]);

  const handleOpenModal = () => {
    setModalOpened(true);
  };

  const validationCallback = async () => {
    const hasPassedValidation = await checkFieldsComplete();
    if (hasPassedValidation) {
      setActiveTab(currentTab);
    }
    return hasPassedValidation;
  };

  useEffect(() => {
    setActiveTab(currentTab);
  }, [currentTab]);

  console.log('activeTab', activeTab);
  return (
    <OrganizationProvider>
      <Container size="xl" style={{ marginTop: '0', paddingTop: '0' }}>
        <ButtonContainer>
          <PrevStepButton enabled={enabled} />

          <ClinicRegistrationStepper />

          {activeStep < subMenu.length - 1 ? (
            <ModalsProvider>
              <NextStepButton
                enabled={enabled && activeTab !== 'employees'}
                validationCallback={validationCallback}
              />
            </ModalsProvider>
          ) : (
            <CompleteStepButton
              enabled={enabled}
              onClick={() => {
                handleOpenModal();
              }}
            />
          )}
        </ButtonContainer>

        <Title style={{ textAlign: 'center', marginBottom: '2rem' }}>
          Clinic Portal
        </Title>

        {/* <InsuranceMemberProvider>
          <CompleteRegistrationModal
            opened={modalOpened}
            onClose={() => setModalOpened(false)}
          />
        </InsuranceMemberProvider> */}

        <Tabs value={activeTab} keepMounted={false}>
          <Tabs.Panel value="practice-info">
            <PracticeInfoTab
              onFieldsCompleteChange={setIsPracticeInfoComplete}
            />
          </Tabs.Panel>
          <Tabs.Panel value="employees">
            <EmployeeManagementTab />
          </Tabs.Panel>
          {/* <Tabs.Panel value="payment-information">
            <PaymentInformationTab
              onFieldsCompleteChange={setIsPaymentInfoComplete}
            />
          </Tabs.Panel> */}
        </Tabs>
      </Container>
    </OrganizationProvider>
  );
};

const InsuranceAdminPortalWrapped: React.FC = () => {
  const subMenu = subMenuList.clinic.menu;
  return (
    <StepperProvider totalSteps={3} subMenu={subMenu}>
      <Container size="xl" style={{ marginTop: '2rem' }}>
        <InsuranceAdminPortal />
      </Container>
    </StepperProvider>
  );
};

export default InsuranceAdminPortalWrapped;
