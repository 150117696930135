import React, { useState, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import { jwtDecode } from 'jwt-decode';

interface ProtectedRouteProps {
  requiredPrivilege: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  requiredPrivilege,
}) => {
  const auth = useAuth();
  const token = auth.getToken();
  const currentUrl = window.location.href;
  const [idledTime, setIdledTime] = useState(new Date().getTime());

  useEffect(() => {
    setIdledTime(new Date().getTime());
  }, [currentUrl]);

  // Determine the appropriate login route based on the current URL
  const loginRoute = currentUrl.includes('/wecare')
    ? '/wecare/login'
    : '/login';

  // Redirect to the appropriate login page if no token is found
  if (!token) {
    return <Navigate to={loginRoute} />;
  }

  try {
    const decodedToken: any = jwtDecode(token);
    console.log('decodedToken:::', decodedToken);

    // kicks out user when ProtectedRoute was not recalled within 1hr
    const idledMinutes = 60; // set to 0.1 to test
    if (new Date().getTime() > idledTime + idledMinutes * 60 * 1000) {
      alert("You've been logout due to inactivity...");
      auth.logoutAuth();
    }

    // logout when auth is expired
    if (new Date(decodedToken.exp * 1000).getTime() < new Date().getTime()) {
      auth.logoutAuth();
    }

    if (
      decodedToken.privileges &&
      decodedToken.privileges.some((p) => p.type === requiredPrivilege) &&
      decodedToken.privileges.some((p) => p.approved === true)
    ) {
      return <Outlet />;
    } else {
      return <Navigate to="/no-access" />;
    }
  } catch (e) {
    console.error('Token decoding failed:', e);
    return <Navigate to={loginRoute} />;
  }
};

export default ProtectedRoute;
