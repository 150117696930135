import {
  ReactNode,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  useMemo,
} from 'react';
import {
  Button,
  Stepper,
  Container,
  Title,
  Paper,
  Grid,
  Group,
  Tabs,
} from '@mantine/core';
import {
  IconUserCheck,
  IconFriends,
  IconNotebook,
  IconBuildingBank,
  IconX,
  IconChevronLeft,
  IconChevronRight,
  IconRosetteDiscountCheckFilled,
} from '@tabler/icons-react';
import { useMediaQuery } from '@mantine/hooks';
import { useLocation } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { cp } from 'fs';
import { ModalsProvider } from '@mantine/modals';
import classes from './RegistrationSteppers.module.css';

interface StepperContextType {
  stepsDone: boolean[];
  markStepAsDone: (tabKey?: string, done?: boolean) => void;
  enabled: boolean;
  setEnabled: (enbale: boolean) => void;
  activeStep: number;
  setActiveStep: (
    step: number | ((current: number) => number) | string,
  ) => void;
  subMenu: any;
}

const StepperContext = createContext<StepperContextType | undefined>(undefined);

export const StepperProvider: React.FC<{
  children: ReactNode;
  totalSteps: number;
  subMenu: any;
}> = ({ children, totalSteps, subMenu }) => {
  let userMetadata;
  try {
    const userMetadataString = localStorage.getItem('userMetadata');
    userMetadata = userMetadataString
      ? JSON.parse(userMetadataString)
      : undefined;
  } catch (e) {
    localStorage.setItem('userMetadata', '{}');
  }

  const [stepsDone, setStepsDone] = useState<boolean[]>(
    userMetadata?.stepsDone || Array(totalSteps).fill(false),
  );
  const [enabled, setEnabled] = useState(true);
  const [activeStep, setActiveStep] = useState<number>(0);

  /**
   * Marks a step in the stepper as done (showing a check mark on the icon), and optionally validates the step.
   *
   * @param tabKey - The key of the tab to mark as done. If provided, the function also performs validation for that step.
   */
  const markStepAsDone = (tabKey?: string, done = true) => {
    const keys = subMenu.map((m) => m.key);
    const stepIndex = keys.indexOf(tabKey);
    try {
      const userMetadataString = localStorage.getItem('userMetadata');
      const userMetadata = userMetadataString
        ? JSON.parse(userMetadataString)
        : {};
      userMetadata.stepsDone = stepsDone;
      userMetadata.stepsDone[stepIndex || activeStep] = done;
      localStorage.setItem('userMetadata', JSON.stringify(userMetadata));
    } catch (e) {}

    setStepsDone((prev) => {
      const newStepsDone = [...prev];
      newStepsDone[stepIndex || activeStep] = true;
      return newStepsDone;
    });
  };

  const location = useLocation();

  useEffect(() => {
    const hash = window.location.hash.replace('#', '');
    const idx = subMenu.indexOf(subMenu.filter((m) => m.key === hash)[0]);
    setActiveStep(idx < 0 ? 0 : idx);
  }, [location]);

  return (
    <StepperContext.Provider
      value={{
        stepsDone,
        enabled,
        setEnabled,
        markStepAsDone,
        activeStep,
        setActiveStep,
        subMenu,
      }}
    >
      {children}
    </StepperContext.Provider>
  );
};

interface RegStepperProps {
  activeStep?: number;
}

export const useStepper = () => {
  const context = useContext(StepperContext);
  if (!context)
    throw new Error('useStepper must be used within a StepperProvider');
  return context;
};

interface NavButtonProps {
  enabled?: boolean;
  validationCallback?: () => Promise<boolean>;
  onClick?: () => void;
}

export const PrevStepButton: React.FC<NavButtonProps> = ({
  enabled = false,
}) => {
  const { activeStep, subMenu } = useStepper();

  const prevStep = () => {
    window.location.hash = subMenu[activeStep - 1].key;
  };

  return (
    <Button
      disabled={!enabled || activeStep == 0}
      variant="default"
      onClick={prevStep}
      className={classes.prevStepButton}
      color="blue"
      size="lg"
    >
      <IconChevronLeft /> Back
    </Button>
  );
};

export const NextStepButton: React.FC<NavButtonProps> = ({
  enabled = true,
  validationCallback,
}) => {
  const { stepsDone, activeStep, subMenu } = useStepper();
  const nextStep = async () => {
    if (subMenu?.allowJumpingBetweenForms) {
      window.location.hash = subMenu[activeStep + 1].key;
      return true;
    }
    try {
      if (
        !subMenu?.allowJumpingBetweenFormsvalidationCallback &&
        !(await validationCallback())
      ) {
        notifications.show({
          title: 'Incomplete Information',
          message: 'Please complete all required fields before proceeding.',
          color: 'red',
          position: 'bottom-right',
        });
        return false;
      } else {
        window.location.hash = subMenu[activeStep + 1].key;
        return true;
      }
    } catch (e) {
      notifications.show({
        title: 'Incomplete Information',
        message: e,
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  return (
    <Button
      disabled={!enabled}
      onClick={nextStep}
      className={classes.nextStepButton}
      size="lg"
    >
      Next <IconChevronRight />
    </Button>
  );
};

export const CompleteStepButton: React.FC<NavButtonProps> = ({
  enabled = true,
  onClick,
}) => {
  return (
    <Button
      color="lime"
      disabled={!enabled}
      onClick={() => {
        onClick();
      }}
      className={classes.completeRegButton}
      size="lg"
    >
      <IconRosetteDiscountCheckFilled />
      &nbsp;Complete Registration
    </Button>
  );
};

interface ButtonContainernProps {
  children: ReactNode;
}

export const ButtonContainer: React.FC<ButtonContainernProps> = ({
  children,
}) => {
  const groupRef = useRef(null);

  return (
    <div className={classes.buttonContainer}>
      <Container pt="16" pb="0">
        <Group ref={groupRef} justify="space-between">
          {children}
        </Group>
      </Container>
    </div>
  );
};

export const IndependentPhysRegStepper: React.FC<RegStepperProps> = () => {
  const { enabled, stepsDone, activeStep, subMenu } = useStepper();

  return (
    <div className={classes.stepperDiv}>
      <Stepper
        active={activeStep}
        onStepClick={(step) => {
          if (enabled && subMenu?.allowJumpingBetweenForms) {
            window.location.hash = subMenu[step].key;
          }
        }}
      >
        <Stepper.Step
          completedIcon={activeStep > 0 && stepsDone[0] ? null : <IconX />}
          color={activeStep > 0 && !stepsDone[0] ? 'red' : 'blue'}
          icon={<IconUserCheck />}
        />
        <Stepper.Step
          completedIcon={activeStep > 0 && stepsDone[1] ? null : <IconX />}
          color={activeStep > 0 && !stepsDone[1] ? 'red' : 'blue'}
          icon={<IconFriends />}
        />
        <Stepper.Step
          completedIcon={activeStep > 1 && stepsDone[2] ? null : <IconX />}
          color={activeStep > 1 && !stepsDone[2] ? 'red' : 'blue'}
          icon={<IconNotebook />}
        />
        <Stepper.Step
          completedIcon={activeStep > 2 && stepsDone[3] ? null : <IconX />}
          color={activeStep > 2 && !stepsDone[3] ? 'red' : 'blue'}
          icon={<IconNotebook />}
        />
        <Stepper.Step
          completedIcon={activeStep > 4 && stepsDone[4] ? null : <IconX />}
          color={activeStep > 4 && !stepsDone[4] ? 'red' : 'blue'}
          icon={<IconBuildingBank />}
        />
        <Stepper.Completed>
          <Paper withBorder shadow="xs" p="md" w="full">
            <Grid justify="space-between" align="center">
              <Grid.Col span={11}>
                <Title order={3}>Thank you for Registering...</Title>
              </Grid.Col>
            </Grid>
          </Paper>
        </Stepper.Completed>
      </Stepper>
    </div>
  );
};

export const EmployeeRegistrationStepper: React.FC<RegStepperProps> = () => {
  const { enabled, stepsDone, activeStep, subMenu } = useStepper();

  return (
    <div className={classes.stepperDiv}>
      <Stepper
        active={activeStep}
        onStepClick={(step) => {
          if (enabled && subMenu?.allowJumpingBetweenForms) {
            window.location.hash = subMenu[step].key;
          }
        }}
      >
        <Stepper.Step
          completedIcon={activeStep > 0 && stepsDone[0] ? null : <IconX />}
          color={activeStep > 0 && !stepsDone[0] ? 'red' : 'blue'}
          icon={<IconUserCheck />}
        />
        <Stepper.Step
          completedIcon={activeStep > 1 && stepsDone[1] ? null : <IconX />}
          color={activeStep > 1 && !stepsDone[1] ? 'red' : 'blue'}
          icon={<IconFriends />}
        />
        <Stepper.Step
          completedIcon={activeStep > 2 && stepsDone[2] ? null : <IconX />}
          color={activeStep > 2 && !stepsDone[2] ? 'red' : 'blue'}
          icon={<IconNotebook />}
        />
        <Stepper.Step
          completedIcon={activeStep > 3 && stepsDone[3] ? null : <IconX />}
          color={activeStep > 3 && !stepsDone[3] ? 'red' : 'blue'}
          icon={<IconNotebook />}
        />
        <Stepper.Completed>
          <Paper withBorder shadow="xs" p="md" w="full">
            <Grid justify="space-between" align="center">
              <Grid.Col span={11}>
                <Title order={3}>Review your information...</Title>
              </Grid.Col>
            </Grid>
            Click back button to get to previous step
          </Paper>
        </Stepper.Completed>
      </Stepper>
    </div>
  );
};

export const ClinicRegistrationStepper: React.FC<RegStepperProps> = () => {
  const { enabled, stepsDone, activeStep, subMenu } = useStepper();

  return (
    <div className={classes.stepperDiv}>
      <Stepper
        active={activeStep}
        onStepClick={(step) => {
          if (enabled && subMenu?.allowJumpingBetweenForms) {
            window.location.hash = subMenu[step].key;
          }
        }}
      >
        <Stepper.Step
          completedIcon={activeStep > 0 && stepsDone[0] ? null : <IconX />}
          color={activeStep > 0 && !stepsDone[0] ? 'red' : 'blue'}
          icon={<IconUserCheck />}
        />
        <Stepper.Step
          completedIcon={activeStep > 1 && stepsDone[1] ? null : <IconX />}
          color={activeStep > 1 && !stepsDone[1] ? 'red' : 'blue'}
          icon={<IconFriends />}
        />
        <Stepper.Step
          completedIcon={activeStep > 2 && stepsDone[2] ? null : <IconX />}
          color={activeStep > 2 && !stepsDone[2] ? 'red' : 'blue'}
          icon={<IconBuildingBank />}
        />
        <Stepper.Completed>
          <Paper withBorder shadow="xs" p="md" w="full">
            <Grid justify="space-between" align="center">
              <Grid.Col span={11}>
                <Title order={3}>Review your information...</Title>
              </Grid.Col>
            </Grid>
            Click back button to get to previous step
          </Paper>
        </Stepper.Completed>
      </Stepper>
    </div>
  );
};
