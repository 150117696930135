import { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { JwtToken } from '../../types/common.types';
import useHttpClient from './useHttpClient';
export const useAuth = () => {
  const [organizationId, setOrganizationId] = useState<number>(0);
  const [subscriberMemberId, setSubscriberMemberId] = useState<number>(0);
  const [wecareMemberId, setWecareMemberId] = useState<number>(0);
  const httpClient = useHttpClient();
  const token = localStorage.getItem('token');
  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode<JwtToken>(token);

        // Find the insurance_organization privilege
        const organizationPrivilege = decodedToken.privileges.find(
          (privilege) => privilege.type === 'insurance_organization',
        );
        if (organizationPrivilege) {
          setOrganizationId(organizationPrivilege.serviceid);
        }

        // Find the insurance_member privilege
        let insuranceMemberPrivilege = decodedToken.privileges.find(
          (privilege) => privilege.type === 'insurance_physician',
        );
        if (!insuranceMemberPrivilege) {
          insuranceMemberPrivilege = decodedToken.privileges.find(
            (privilege) => privilege.type === 'insurance_employee',
          );
        }
        if (insuranceMemberPrivilege) {
          setSubscriberMemberId(insuranceMemberPrivilege.serviceid);
        }

        // Find the insurance_physician privilege only
        const insurancePhysicianPrivilege = decodedToken.privileges.find(
          (privilege) => privilege.type === 'insurance_physician',
        );
        if (insurancePhysicianPrivilege) {
          // Set the subscriberMemberId based on the insurance_physician privilege
          setSubscriberMemberId(insurancePhysicianPrivilege.serviceid);

          // Fetch organizationId by calling the /me endpoint
          const fetchOrganizationId = async () => {
            try {
              const me = await httpClient.get(`/api/me`);
              setOrganizationId(me.data.organizationId);
            } catch (error) {
              console.error('Failed to fetch organizationId from /me:', error);
            }
          };

          fetchOrganizationId();
        }

        // Find the wecaremember privilege
        const wecarePrivilege = decodedToken.privileges.find(
          (privilege) => privilege.type === 'wecaremember',
        );
        if (wecarePrivilege) {
          setWecareMemberId(wecarePrivilege.serviceid);
        }
      } catch (error) {
        console.error('Failed to decode JWT:', error);
      }
    }
  }, [token]);

  console.log( { organizationId, subscriberMemberId, wecareMemberId })
  return { organizationId, subscriberMemberId, wecareMemberId };
};
