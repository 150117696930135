import React, { useEffect, useState } from 'react';
import { Container, Paper, Title, Text, Button, Grid } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import useHttpClient from '../hooks/useHttpClient';

interface Clinic {
    id: string;
    name: string;
    npi: string;
    message?: string;
    npimatch: string;
    street1: string;
    street2?: string;
    city: string;
    state: string;
    zip: string;
    county: string;
    tin: string;
}

const InsuranceClinicApprovalPage: React.FC = () => {
    const [clinics, setClinics] = useState<Clinic[]>([]);
    const httpClient = useHttpClient();

    useEffect(() => {
        const fetchClinics = async () => {
            try {
                const response = await httpClient.get('/api/organization/approve');

                if (response.status === 200) {
                    const data = response.data;
                    setClinics(data);
                } else {
                    notifications.show({
                        title: 'Error',
                        message: 'Failed to fetch clinics for approval.',
                        color: 'red',
                        position: 'bottom-right',
                    });
                }
            } catch (error) {
                console.error('Error fetching clinics:', error);
                notifications.show({
                    title: 'Error',
                    message: 'An error occurred while fetching clinics.',
                    color: 'red',
                    position: 'bottom-right',
                });
            }
        };

        fetchClinics();
    }, []);

    // Function to handle approval or denial
    const handleAction = async (clinicId: string, approved: boolean) => {
        try {
            const response = await httpClient.put('/api/user/approve/clinic', {
                id: clinicId,
                approved,
                type: 'insurance_organization',
            });

            if (response.status === 200) {
                notifications.show({
                    title: approved ? 'Clinic Approved' : 'Clinic Denied',
                    message: approved
                        ? 'Clinic has been approved successfully.'
                        : 'Clinic has been denied.',
                    color: approved ? 'green' : 'red',
                    position: 'bottom-right',
                });
                // Remove the clinic from the list after action
                setClinics((prevClinics) =>
                    prevClinics.filter((clinic) => clinic.id !== clinicId),
                );
            } else {
                throw new Error('Action failed');
            }
        } catch (error) {
            console.error('Error performing action:', error);
            notifications.show({
                title: 'Error',
                message: 'Failed to update clinic status.',
                color: 'red',
                position: 'bottom-right',
            });
        }
    };

    return (
        <Container>
            <Title order={2} mb="md" ta="center">
                Clinics Pending Approval
            </Title>
            {clinics.length === 0 ? (
                <Text ta="center">No clinics requiring approval.</Text>
            ) : (
                clinics.map((clinic, index) => (
                    <Paper key={index} withBorder shadow="sm" p="md" mb="md">
                        <Grid>
                            <Grid.Col span={6}>
                                <Text>
                                    <strong>Clinic Name:</strong> {clinic.name}
                                </Text>
                                <Text>
                                    <strong>Address:</strong> {clinic.street1}{' '}
                                    {clinic.street2 && `${clinic.street2}, `}
                                    {clinic.city}, {clinic.state} {clinic.zip}
                                </Text>
                                <Text>
                                    <strong>TIN:</strong> {clinic.tin}
                                </Text>
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <Text>
                                    <strong>NPI:</strong> {clinic.npi}
                                </Text>
                                <Text>
                                    <strong>NPI Match:</strong>{' '}
                                    <span
                                        style={{
                                            color:
                                                clinic.npimatch === 'match'
                                                    ? 'green'
                                                    : 'red',
                                        }}
                                    >
                                        {clinic.npimatch}
                                    </span>
                                </Text>
                            </Grid.Col>
                        </Grid>
                        {clinic.message && (
                            <Text>
                                <strong>Message:</strong> {clinic.message}
                            </Text>
                        )}
                        <Grid justify="space-between" mt="md">
                            <Grid.Col span={6}>
                                <Button
                                    color="green"
                                    fullWidth
                                    onClick={() => handleAction(clinic.id, true)}
                                    style={{ marginRight: 10 }}
                                >
                                    Approve
                                </Button>
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <Button
                                    color="red"
                                    variant="outline"
                                    fullWidth
                                    onClick={() => handleAction(clinic.id, false)}
                                >
                                    Deny
                                </Button>
                            </Grid.Col>
                        </Grid>
                    </Paper>
                ))
            )}
        </Container>
    );
};

export default InsuranceClinicApprovalPage;
