import React, { useEffect, useState } from 'react';
import { Container, Paper, Title, Text, Button, Grid } from '@mantine/core';
import { notifications } from '@mantine/notifications';

interface Member {
  id: string; // Added the id field to identify the member
  firstname: string;
  lastname: string;
  licensenumber: string;
  licensestate: string;
  licenseexpiration: string;
  birthday: string;
  npi: string;
  npimatch: string;
  message?: string;
}

const WecareMemberApprovalPage: React.FC = () => {
  const [members, setMembers] = useState<Member[]>([]);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await fetch('/api/members/approve', { method: 'GET' });
        if (response.ok) {
          const data = await response.json();
          setMembers(data);
        } else {
          notifications.show({
            title: 'Error',
            message: 'Failed to fetch members for approval.',
            color: 'red',
            position: 'bottom-right',
          });
        }
      } catch (error) {
        console.error('Error fetching members:', error);
        notifications.show({
          title: 'Error',
          message: 'An error occurred while fetching members.',
          color: 'red',
          position: 'bottom-right',
        });
      }
    };

    fetchMembers();
  }, []);

  // Function to handle approval or denial
  const handleAction = async (memberId: string, approved: boolean, type: string) => {
    try {
      const response = await fetch('/api/user/approve/wecare', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: memberId, approved, type }),
      });

      if (response.ok) {
        notifications.show({
          title: approved ? 'Member Approved' : 'Member Denied',
          message: approved
            ? 'Member has been approved successfully.'
            : 'Member has been denied.',
          color: approved ? 'green' : 'red',
          position: 'bottom-right',
        });
        // Remove the member from the list after action
        setMembers((prevMembers) => prevMembers.filter((member) => member.id !== memberId));
      } else {
        throw new Error('Action failed');
      }
    } catch (error) {
      console.error('Error performing action:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to update member status.',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  return (
    <Container>
      <Title order={2} mb="md" ta="center">Members Pending Approval</Title>
      {members.length === 0 ? (
        <Text ta="center">No members requiring approval.</Text>
      ) : (
        members.map((member, index) => (
          <Paper key={index} withBorder shadow="sm" p="md" mb="md">
            <Grid>
              <Grid.Col span={6}>
                <Text><strong>Name:</strong> {member.firstname} {member.lastname}</Text>
                <Text><strong>Medical License:</strong> {member.licensenumber} ({member.licensestate})</Text>
                <Text><strong>License Expiration:</strong> {new Date(member.licenseexpiration).toLocaleDateString()}</Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text><strong>Date of Birth:</strong> {new Date(member.birthday).toLocaleDateString()}</Text>
                <Text><strong>NPI:</strong> {member.npi}</Text>
                <Text>
                  <strong>NPI Match:</strong>{' '}
                  <span
                    style={{
                      color: member.npimatch === 'match' ? 'green' : 'red',
                    }}
                  >
                    {member.npimatch}
                  </span>
                </Text>
              </Grid.Col>
            </Grid>
            {member.message && (
              <Text mt="md"><strong>Message:</strong> {member.message}</Text>
            )}
            <Grid justify="space-between" mt="md">
              <Grid.Col span={6}>
                <Button
                  color="green"
                  fullWidth
                  onClick={() => handleAction(member.id, true, 'wecaremember')}
                  style={{ marginRight: 10 }}
                >
                  Approve
                </Button>
              </Grid.Col>
              <Grid.Col span={6}>
                <Button
                  color="red"
                  variant="outline"
                  fullWidth
                  onClick={() => handleAction(member.id, false, 'wecaremember')}
                >
                  Deny
                </Button>
              </Grid.Col>
            </Grid>
          </Paper>
        ))
      )}
    </Container>
  );
};

export default WecareMemberApprovalPage;
