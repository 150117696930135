import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Select,
  Textarea,
  Grid,
  Paper,
  Group,
  Collapse,
  Input,
} from '@mantine/core';
import { IMaskInput } from 'react-imask';
import { notifications } from '@mantine/notifications';
import useHttpClient from './hooks/useHttpClient';
import { STATES_ARRAY } from '../types/members.types';
import CustomDateInput from './CustomDateInput';

interface FormProps {
  memberId: number;
}

const HospitalForm: React.FC<FormProps> = ({ memberId }) => {
  const { control, register, handleSubmit, watch, reset } = useForm({});
  const httpClient = useHttpClient();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'hospitals',
  });

  const [collapsedIndices, setCollapsedIndices] = useState(
    fields.map(() => true),
  );
  const [initialData, setInitialData] = useState<any>();

  const fetchHospitals = async () => {
    try {
      const response = await httpClient.get(`/api/hospitals/${memberId}`);
      if (!response.status.toString().startsWith('2')) {
        throw new Error('Failed to fetch hospitals');
      }
      const fetchedHospitals = await response.data;

      const sortedHospitals = fetchedHospitals.sort(
        (a: any, b: any) => a.id - b.id,
      );

      console.log('before ', fields);
      reset({ hospitals: sortedHospitals });
      setInitialData(sortedHospitals);
      console.log('after ', fields);
    } catch (error) {
      console.error('Error fetching hospitals:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch hospitals',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  useEffect(() => {
    fetchHospitals();
  }, [memberId]);

  const toggleCollapse = (index: number) => {
    setCollapsedIndices((prev) => {
      const newCollapsedIndices = [...prev];
      newCollapsedIndices[index] = !newCollapsedIndices[index];
      return newCollapsedIndices;
    });
  };

  const onSubmit = async (data: any) => {
    console.log('submit form values', data);

    const createHospital = async (hospital: any) => {
      const endpoint = `${process.env.REACT_APP_API_URL}/api/hospitals`;
      const method = 'POST';

      const response = await fetch(endpoint, {
        method: method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(hospital),
      });

      return response.json();
    };

    const updateHospital = async (hospital: any) => {
      const endpoint = `${process.env.REACT_APP_API_URL}/api/hospitals/${hospital.id}`;
      const method = 'PUT';

      const response = await fetch(endpoint, {
        method: method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(hospital),
      });
      return response.json();
    };

    const deleteHospital = async (id: number) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/hospitals/${id}`,
        {
          method: 'DELETE',
        },
      );
      console.log('delete response', response);
    };

    // Determine deletions, creations, and updates
    const initialIds = new Set<number>(initialData?.map((h: any) => h.id));
    const currentIds = new Set<number>(data.hospitals.map((h: any) => h.id));
    const deletedIds = [...initialIds].filter(
      (id): id is number => !currentIds.has(id),
    );

    const createTasks = data.hospitals
      .filter((h: any) => !h.id || h.id === 0)
      .map((h: any) => createHospital(h));
    const updateTasks = data.hospitals
      .filter((h: any) => h.id && h.id > 0 && initialIds.has(h.id))
      .map((h: any) => updateHospital(h));

    const deleteTasks = deletedIds.map((id) => deleteHospital(id));

    try {
      const results = await Promise.all([
        ...createTasks,
        ...updateTasks,
        ...deleteTasks,
      ]);
      console.log('Results:', results);
      fetchHospitals();

      notifications.show({
        title: 'Success',
        message: 'Hospitals updated successfully',
        color: 'green',
        position: 'bottom-right',
      });
    } catch (error) {
      console.error('Error submitting hospital form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit hospital',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  return (
    <Container>
      {fields.toString()}
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        {fields.map((item, index) => {
          const whoAdmits = watch(`hospitals.${index}.whoadmits`);

          return (
            <Paper key={item.id} shadow="xs" p="md" withBorder>
              <Group>
                <h3>Hospital {index + 1}</h3>
                <Button onClick={() => toggleCollapse(index)} size="xs">
                  {collapsedIndices[index] ? 'Expand' : 'Collapse'}
                </Button>
                <Button color="red" onClick={() => remove(index)} size="xs">
                  Remove
                </Button>
              </Group>
              <Collapse in={!collapsedIndices[index]}>
                <Grid>
                  <Grid.Col span={6}>
                    <TextInput
                      label="Hospital Name"
                      {...register(`hospitals.${index}.hospitalname`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Controller
                      name={`hospitals.${index}.state`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          label="State"
                          data={STATES_ARRAY.map((state) => ({
                            value: state,
                            label: state,
                          }))}
                          clearable
                          searchable
                        />
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Controller
                      name={`hospitals.${index}.country`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          label="Country"
                          data={[{ value: 'US', label: 'United States' }]}
                          clearable
                          searchable
                        />
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      label="Organization (Type 2) NPI"
                      {...register(`hospitals.${index}.organizationnpi`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <CustomDateInput
                      control={control}
                      name={`hospitals.${index}.startdate`}
                      label="Start Date"
                    />
                  </Grid.Col>

                  <Grid.Col span={6}>
                    <CustomDateInput
                      control={control}
                      name={`hospitals.${index}.enddate`}
                      label="End Date"
                    />
                  </Grid.Col>

                  <Grid.Col span={6}>
                    <Controller
                      name={`hospitals.${index}.whoadmits`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          label="Who admits for you?"
                          data={[
                            { value: 'Myself', label: 'Myself' },
                            {
                              value: 'A provider in my practice',
                              label: 'A provider in my practice',
                            },
                            {
                              value: 'A provider not in my practice',
                              label: 'A provider not in my practice',
                            },
                            {
                              value: 'A hospitalist group',
                              label: 'A hospitalist group',
                            },
                          ]}
                          clearable
                        />
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Controller
                      name={`hospitals.${index}.admittingarrangementstatus`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          label="Admitting Arrangement Status"
                          data={[
                            { value: 'active', label: 'Active' },
                            { value: 'inactive', label: 'Inactive' },
                          ]}
                          clearable
                        />
                      )}
                    />
                  </Grid.Col>
                </Grid>
                <div style={{ marginTop: '16px' }}></div>
                {whoAdmits && whoAdmits !== 'Myself' && (
                  <Paper shadow="xs" p="sm" withBorder>
                    <h4>Admitter Information</h4>
                    <Grid>
                      {(whoAdmits === 'A provider in my practice' ||
                        whoAdmits === 'A provider not in my practice') && (
                          <>
                            <Grid.Col span={6}>
                              <TextInput
                                label="Admitter First Name"
                                {...register(
                                  `hospitals.${index}.admitterfirstname`,
                                )}
                              />
                            </Grid.Col>
                            <Grid.Col span={6}>
                              <TextInput
                                label="Admitter Last Name"
                                {...register(
                                  `hospitals.${index}.admitterlastname`,
                                )}
                              />
                            </Grid.Col>
                            <Grid.Col span={6}>
                              <Controller
                                name={`hospitals.${index}.samespecialty`}
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    label="Is the admitting provider's specialty the same as your specialty?"
                                    data={[
                                      { value: 'Yes', label: 'Yes' },
                                      { value: 'No', label: 'No' },
                                    ]}
                                    clearable
                                  />
                                )}
                              />
                            </Grid.Col>
                            <Grid.Col span={6}>
                              <TextInput
                                label="Admitter NPI"
                                {...register(`hospitals.${index}.admitternpi`)}
                              />
                            </Grid.Col>
                          </>
                        )}
                      {whoAdmits === 'A hospitalist group' && (
                        <>
                          <Grid.Col span={6}>
                            <TextInput
                              label="Admitting Group Name"
                              {...register(
                                `hospitals.${index}.admittinggroupname`,
                              )}
                            />
                          </Grid.Col>
                          <Grid.Col span={6}>
                            <TextInput
                              label="Organization NPI Number (Type 2)"
                              {...register(`hospitals.${index}.orgnpi`)}
                            />
                          </Grid.Col>
                        </>
                      )}
                      {(whoAdmits === 'A provider in my practice' ||
                        whoAdmits === 'A provider not in my practice' ||
                        whoAdmits === 'A hospitalist group') && (
                          <>
                            <Grid.Col span={6}>
                              <Controller
                                name={`hospitals.${index}.admitterphone`}
                                control={control}
                                render={({ field }) => (
                                  <Input.Wrapper label="Admitter Phone">
                                    <Input
                                      component={IMaskInput}
                                      {...field}
                                      mask="(000) 000-0000"
                                      placeholder="(XXX) XXX-XXXX"
                                    />
                                  </Input.Wrapper>
                                )}
                              />
                            </Grid.Col>
                            <Grid.Col span={6}>
                              <TextInput
                                label="Admitter Email"
                                {...register(`hospitals.${index}.admitteremail`)}
                              />
                            </Grid.Col>
                            <Grid.Col span={12}>
                              <Textarea
                                label="Arrangement Description"
                                {...register(
                                  `hospitals.${index}.arrangementdescription`,
                                )}
                              />
                            </Grid.Col>
                          </>
                        )}
                    </Grid>
                  </Paper>
                )}
              </Collapse>
            </Paper>
          );
        })}
        <Group mt="md">
          <Button
            onClick={() =>
              append({
                memberId,
                hospitalname: '',
                state: '',
                country: 'US',
                admittingarrangementstatus: '',
                startdate: null,
                enddate: null,
                whoadmits: '',
                admitterfirstname: '',
                admitterlastname: '',
                admittinggroupname: '',
                samespecialty: '',
                admitterphone: '',
                admitteremail: '',
                admitternpi: '',
                admittergroupname: '',
                orgnpi: '',
                arrangementdescription: '',
                organizationnpi: '',
              })
            }
          >
            Add Hospital
          </Button>
        </Group>
        <Group mt="md">
          <Button type="submit">Save</Button>
        </Group>
      </form>
    </Container>
  );
};

export default HospitalForm;
