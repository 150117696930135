import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Select,
  Group,
  Collapse,
  Paper,
  Grid,
  Input,
  MultiSelect,
} from '@mantine/core';
import { IMaskInput } from 'react-imask';
import { notifications } from '@mantine/notifications';
import useHttpClient from './hooks/useHttpClient';
import { STATES_ARRAY } from '../types/members.types';
import { useLoading } from './MemberPortal';
import CustomDateInput from './CustomDateInput';

interface FormProps {
  memberId: number;
}

const LiabilityForm: React.FC<FormProps> = ({ memberId }) => {
  const { control, register, handleSubmit, reset, watch } = useForm({});
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'liabilities',
  });
  const httpClient = useHttpClient();
  const { stopLoading } = useLoading();

  const [collapsedIndices, setCollapsedIndices] = useState<boolean[]>([]);
  const [initialData, setInitialData] = useState<any>();
  const [hasLiabilitiesExtra, setHasLiabilitiesExtra] = useState(false);
  const [practiceOptions, setPracticeOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const fetchLiabilities = async () => {
    try {
      let sortedLiabilities = [];
      try {
        const liabilitiesResponse = await httpClient.get(
          `/api/liabilities/${memberId}`,
        );
        const fetchedLiabilities = liabilitiesResponse.data;
        sortedLiabilities = fetchedLiabilities
          .map((liability: any) => ({
            ...liability,
            coveredlocations: liability.coveredlocations.map((loc: number) =>
              loc.toString(),
            ),
          }))
          .sort((a: any, b: any) => {
            const expirationDateComparison =
              new Date(a.currentexpirationdate).getTime() -
              new Date(b.currentexpirationdate).getTime();
            if (expirationDateComparison !== 0) return expirationDateComparison;
            const policyNumberComparison = a.policynumber.localeCompare(
              b.policynumber,
            );
            if (policyNumberComparison !== 0) return policyNumberComparison;
            return a.id - b.id;
          });
      } catch (e) {
        console.log('Error fetching liabilities: ', e);
      }

      let fetchedExtra = { coveredbyftca: null, uninsured: null };
      try {
        const liabilitiesextraResponse = await httpClient.get(
          `/api/liabilitiesextra/${memberId}`,
        );

        if (
          liabilitiesextraResponse.data &&
          Object.keys(liabilitiesextraResponse.data).length > 0
        ) {
          fetchedExtra = liabilitiesextraResponse.data;
          setHasLiabilitiesExtra(true);
        } else {
          setHasLiabilitiesExtra(false);
        }
      } catch (e) {
        console.log('Error fetching liabilities extra: ', e);
      }

      const practiceResponse = await httpClient.get(
        `/api/practices/${memberId}`,
      );
      const practices = await practiceResponse.data;
      setPracticeOptions(
        practices.map((p: any) => ({
          value: p.id.toString(),
          label: p.legalbusinessname,
        })),
      );

      reset({
        liabilities: sortedLiabilities,
        coveredbyftca: fetchedExtra[0].coveredbyftca,
        uninsured: fetchedExtra[0].uninsured,
      });
      setInitialData({
        liabilities: sortedLiabilities,
      });
      setCollapsedIndices(sortedLiabilities.map(() => false));
    } catch (error: any) {
      console.error('Error fetching liabilities:', error);
      // notifications.show({
      //   title: 'Error',
      //   message: 'Failed to fetch liabilities',
      //   color: 'red',
      //   position: 'bottom-right',
      // });
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    if (memberId) {
      fetchLiabilities();
    }
  }, [memberId]);

  const toggleCollapse = (index: number) => {
    setCollapsedIndices((prev) => {
      const newCollapsedIndices = [...prev];
      newCollapsedIndices[index] = !newCollapsedIndices[index];
      return newCollapsedIndices;
    });
  };

  const onSubmit = async (data: any) => {
    const formatData = (items: any[]) => {
      return items.map((item) => ({
        ...item,
        memberid: memberId,
        coveredlocations: item.coveredlocations.map((location: string) =>
          parseInt(location, 10),
        ),
      }));
    };

    const formattedData = formatData(data.liabilities);

    const createLiabilities = async (liabilities: any) => {
      const response = await fetch('/api/liabilities', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(liabilities),
      });
      return response.json();
    };

    const updateLiabilities = async (liabilities: any) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/liabilities/${liabilities.id}`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(liabilities),
        },
      );
      return response.json();
    };

    const deleteLiabilities = async (id: number) => {
      await fetch(`${process.env.REACT_APP_API_URL}/api/liabilities/${id}`, {
        method: 'DELETE',
      });
    };

    const createLiabilitiesExtra = async (extraData: any) => {
      const extraDataWithMemberId = { ...extraData, memberid: memberId };
      const response = await fetch('/api/liabilitiesextra', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(extraDataWithMemberId),
      });
      return response.json();
    };

    const updateLiabilitiesExtra = async (extraData: any) => {
      try {
        await fetch(
          `${process.env.REACT_APP_API_URL}/api/liabilitiesextra/${memberId}`,
          {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(extraData),
          },
        );
        // return response.json();
      } catch (e) {
        console.log(e);
      }
    };

    const initialIds = new Set<number>(
      initialData?.liabilities.map((c: any) => c.id),
    );
    const currentIds = new Set<number>(formattedData.map((c: any) => c.id));
    const deletedIds = [...initialIds].filter(
      (id): id is number => !currentIds.has(id),
    );

    const createTasks = formattedData
      .filter((c: any) => !c.id || c.id === 0)
      .map((c: any) => createLiabilities(c));
    const updateTasks = formattedData
      .filter((c: any) => c.id && c.id > 0 && initialIds.has(c.id))
      .map((c: any) => updateLiabilities(c));
    const deleteTasks = deletedIds.map((id) => deleteLiabilities(id));

    const liabilitiesExtraData = {
      coveredbyftca: data.coveredbyftca,
      uninsured: data.uninsured,
    };

    const extraTask = hasLiabilitiesExtra
      ? updateLiabilitiesExtra(liabilitiesExtraData)
      : createLiabilitiesExtra(liabilitiesExtraData);

    try {
      await Promise.all(
        [...createTasks, ...updateTasks, ...deleteTasks, extraTask].filter(
          Boolean,
        ),
      );
      fetchLiabilities();

      notifications.show({
        title: 'Success',
        message: 'Liabilities updated successfully',
        color: 'green',
        position: 'bottom-right',
      });
    } catch (error) {
      console.error('Error submitting liability form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit liability',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  return (
    <Container>
      {fields.toString()}
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <Grid>
          <Grid.Col span={6}>
            <Controller
              name="coveredbyftca"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label="I am covered by FTCA"
                  data={[
                    { value: 'Y', label: 'Yes' },
                    { value: 'N', label: 'No' },
                  ]}
                  clearable
                />
              )}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Controller
              name="uninsured"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label="I am not insured"
                  data={[
                    { value: 'Y', label: 'Yes' },
                    { value: 'N', label: 'No' },
                  ]}
                  clearable
                />
              )}
            />
          </Grid.Col>
        </Grid>
        <div style={{ marginTop: '16px' }}></div>
        {fields.map((item, index) => (
          <Paper key={item.id} shadow="xs" p="md" withBorder>
            <Group>
              <h3>Policy {index + 1}</h3>
              <Button onClick={() => toggleCollapse(index)} size="xs">
                {collapsedIndices[index] ? 'Expand' : 'Collapse'}
              </Button>
              <Button color="red" onClick={() => remove(index)} size="xs">
                Remove
              </Button>
            </Group>
            <Collapse in={!collapsedIndices[index]}>
              <Grid>
                <Grid.Col span={12}>
                  <TextInput
                    label="Policy Number"
                    {...register(`liabilities.${index}.policynumber`)}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Controller
                    name={`liabilities.${index}.coveredlocations`}
                    control={control}
                    render={({ field }) => (
                      <MultiSelect
                        {...field}
                        label="Covered Locations"
                        data={practiceOptions}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <CustomDateInput
                    control={control}
                    name={`liabilities.${index}.currenteffectivedate`}
                    label="Current Effective Date"
                  />
                </Grid.Col>

                <Grid.Col span={6}>
                  <CustomDateInput
                    control={control}
                    name={`liabilities.${index}.currentexpirationdate`}
                    label="Current Expiration Date"
                  />
                </Grid.Col>

                <Grid.Col span={6}>
                  <CustomDateInput
                    control={control}
                    name={`liabilities.${index}.originaleffectivedate`}
                    label="Original Effective Date"
                  />
                </Grid.Col>

                <Grid.Col span={6}>
                  <TextInput
                    label="Carrier"
                    {...register(`liabilities.${index}.carrier`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label={'\u0405treet 1'}
                    {...register(`liabilities.${index}.street1`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label={'\u0405treet 2'}
                    {...register(`liabilities.${index}.street2`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="City"
                    {...register(`liabilities.${index}.city`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Province"
                    {...register(`liabilities.${index}.province`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`liabilities.${index}.country`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Country"
                        data={[{ value: 'US', label: 'United States' }]}
                        clearable
                        searchable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`liabilities.${index}.state`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="State"
                        data={STATES_ARRAY.map((state) => ({
                          value: state,
                          label: state,
                        }))}
                        clearable
                        searchable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="ZIP"
                    {...register(`liabilities.${index}.zip`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Input.Wrapper label="Phone">
                    <Input
                      component={IMaskInput}
                      mask="(000) 000-0000"
                      placeholder="(XXX) XXX-XXXX"
                      {...register(`liabilities.${index}.phone`)}
                    />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Input.Wrapper label="Fax">
                    <Input
                      component={IMaskInput}
                      mask="(000) 000-0000"
                      placeholder="(XXX) XXX-XXXX"
                      {...register(`liabilities.${index}.fax`)}
                    />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`liabilities.${index}.unlimitedcoverage`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Unlimited Coverage"
                        data={[
                          { value: 'Y', label: 'Yes' },
                          { value: 'N', label: 'No' },
                        ]}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Type"
                    {...register(`liabilities.${index}.type`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Amount Per Occurrence"
                    {...register(`liabilities.${index}.amountperoccurrence`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Amount Aggregate"
                    {...register(`liabilities.${index}.amountaggregate`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`liabilities.${index}.tailnosecoverage`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Tail/Nose Coverage"
                        data={[
                          { value: 'Y', label: 'Yes' },
                          { value: 'N', label: 'No' },
                        ]}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`liabilities.${index}.individualcoverage`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Individual Coverage"
                        data={[
                          { value: 'Y', label: 'Yes' },
                          { value: 'N', label: 'No' },
                        ]}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`liabilities.${index}.selfinsured`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Self Insured"
                        data={[
                          { value: 'Y', label: 'Yes' },
                          { value: 'N', label: 'No' },
                        ]}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Institution Affiliation"
                    {...register(`liabilities.${index}.institutionaffiliation`)}
                  />
                </Grid.Col>
              </Grid>
            </Collapse>
          </Paper>
        ))}
        <Group mt="md">
          <Button
            onClick={() =>
              append({
                policynumber: '',
                coveredlocations: [],
                currenteffectivedate: null,
                currentexpirationdate: null,
                originaleffectivedate: null,
                carrier: '',
                street1: '',
                street2: '',
                city: '',
                province: '',
                country: 'US',
                state: '',
                zip: '',
                phone: '',
                fax: '',
                unlimitedcoverage: '',
                type: '',
                amountperoccurrence: null,
                amountaggregate: null,
                tailnosecoverage: '',
                individualcoverage: '',
                selfinsured: '',
                institutionaffiliation: '',
              })
            }
          >
            Add Policy
          </Button>
        </Group>
        <Group mt="md">
          <Button type="submit">Save</Button>
        </Group>
      </form>
    </Container>
  );
};

export default LiabilityForm;
