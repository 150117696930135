import React, { useState } from 'react';
import {
  TextInput,
  Button,
  Paper,
  Container,
  Title,
  Center,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import useHttpClient from '../hooks/useHttpClient';

export default function PasswordResetRequest() {
  const [username, setUsername] = useState('');

  const httpClient = useHttpClient();
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (username) {
      try {
        await httpClient.post('/api/auth/request-password-reset', {
          username,
        });
        showNotification({
          title: 'Success',
          message: 'Password reset instructions have been sent to your email.',
          color: 'green',
        });
      } catch (error) {
        showNotification({
          title: 'Error',
          message: 'Failed to send password reset instructions.',
          color: 'red',
        });
      }
    } else {
      alert('Please enter your username or email');
    }
  };

  return (
    <Container size={420} mt={100} style={{ background: 'none' }}>
      <Paper withBorder shadow="md" p={30} radius="md" mt="lg">
        <form onSubmit={handleSubmit}>
          <Center mb="lg">
            <Title order={2}>Reset Password</Title>
          </Center>
          <TextInput
            label="Username or Email"
            placeholder="Enter your username or email"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            mb="md"
          />
          <Center mt="md">
            <Button type="submit">Send Reset Instructions</Button>
          </Center>
        </form>
      </Paper>
    </Container>
  );
}
