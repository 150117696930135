import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Select,
  Textarea,
  Group,
  Collapse,
  Paper,
  Grid,
  MultiSelect,
  Input,
} from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import { IMaskInput } from 'react-imask';
import { notifications } from '@mantine/notifications';
import { LANGUAGES, STATES_ARRAY } from '../types/members.types';
import useHttpClient from './hooks/useHttpClient';

interface FormProps {
  memberId: number;
}

const PracticeForm: React.FC<FormProps> = ({ memberId }) => {
  const { control, register, handleSubmit, reset } = useForm({});
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'practices',
  });

  const [collapsedIndices, setCollapsedIndices] = useState(
    fields.map(() => true),
  );
  const [initialData, setInitialData] = useState<any>();
  const httpClient = useHttpClient();

  const fetchPractices = async () => {
    try {
      const response = await httpClient.get(`/api/practices/${memberId}`);
      const fetchedPractices = response.data;

      const primaryPractice = fetchedPractices.find(
        (practice: any) => practice.primary,
      );
      const secondaryPractices = fetchedPractices
        .filter((practice: any) => !practice.primary)
        .sort((a: any, b: any) => a.id - b.id);

      const formatPractice = (practice: any) => ({
        ...practice,
        phone: practice.locations[0]?.phone || '',
        street1: practice.locations[0]?.street1 || '',
        street2: practice.locations[0]?.street2 || '',
        city: practice.locations[0]?.city || '',
        state: practice.locations[0]?.state || '',
        zip: practice.locations[0]?.zip || '',
        country: practice.locations[0]?.country || 'US',
        county: practice.locations[0]?.county || '',
        practicelocationemailaddress:
          practice.locations[0]?.practicelocationemailaddress || '',
        practicelocationwebsite:
          practice.locations[0]?.practicelocationwebsite || '',
        appointmentschedulingwebsite:
          practice.locations[0]?.appointmentschedulingwebsite || '',
        appointmentphonenumber:
          practice.locations[0]?.appointmentphonenumber || '',
        faxnumber: practice.locations[0]?.faxnumber || '',
        accessibility: practice.locations[0]?.accessibility || '',
        languages: practice.locations[0]?.languages || [],
        mondaystarttime: practice.locations[0]?.mondaystarttime || '',
        mondayendtime: practice.locations[0]?.mondayendtime || '',
        tuesdaystarttime: practice.locations[0]?.tuesdaystarttime || '',
        tuesdayendtime: practice.locations[0]?.tuesdayendtime || '',
        wednesdaystarttime: practice.locations[0]?.wednesdaystarttime || '',
        wednesdayendtime: practice.locations[0]?.wednesdayendtime || '',
        thursdaystarttime: practice.locations[0]?.thursdaystarttime || '',
        thursdayendtime: practice.locations[0]?.thursdayendtime || '',
        fridaystarttime: practice.locations[0]?.fridaystarttime || '',
        fridayendtime: practice.locations[0]?.fridayendtime || '',
        saturdaystarttime: practice.locations[0]?.saturdaystarttime || '',
        saturdayendtime: practice.locations[0]?.saturdayendtime || '',
        sundaystarttime: practice.locations[0]?.sundaystarttime || '',
        sundayendtime: practice.locations[0]?.sundayendtime || '',
        managerfname: practice.locations[0]?.managerfname || '',
        managerlname: practice.locations[0]?.managerlname || '',
        managerphone: practice.locations[0]?.managerphone || '',
        manageremail: practice.locations[0]?.manageremail || '',
      });

      const formattedPrimaryPractice = primaryPractice
        ? formatPractice(primaryPractice)
        : null;
      const formattedSecondaryPractices = secondaryPractices.map(
        (practice: any) => formatPractice(practice),
      );

      const formattedPractices = [
        formattedPrimaryPractice,
        ...formattedSecondaryPractices,
      ].filter(Boolean);

      reset({ practices: formattedPractices });
      setInitialData(formattedPractices);
    } catch (error) {
      console.error('Error fetching practices:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch practices',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  useEffect(() => {
    if (memberId) {
      fetchPractices();
    }
  }, [memberId]);

  const toggleCollapse = (index: number) => {
    setCollapsedIndices((prev) => {
      const newCollapsedIndices = [...prev];
      newCollapsedIndices[index] = !newCollapsedIndices[index];
      return newCollapsedIndices;
    });
  };

  const onSubmit = async (data: any) => {
    console.log('submit form values', data);

    const formatData = (items: any[]) => {
      return items.map((item) => ({
        ...item,
        memberid: memberId,
      }));
    };

    const formattedData = formatData(data.practices);

    const createPractice = async (practice: any) => {
      const response = await httpClient.post('/api/practices', {
        memberId,
        practices: [practice],
      });
      return response.data;
    };

    const updatePractice = async (practice: any) => {
      const response = await httpClient.put(`/api/practices/${practice.id}`, {
        memberId,
        practices: [practice],
      });
      return response.data;
    };

    const deletePractice = async (id: number) => {
      await httpClient.delete(`/api/practices/${id}`);
      console.log('delete response');
    };

    // Determine deletions, creations, and updates
    const initialIds = new Set<number>(initialData?.map((h: any) => h.id));
    const currentIds = new Set<number>(formattedData.map((h: any) => h.id));
    const deletedIds = [...initialIds].filter(
      (id): id is number => !currentIds.has(id),
    );

    const createTasks = formattedData
      .filter((h: any) => !h.id || h.id === 0)
      .map((h: any) => createPractice(h));
    const updateTasks = formattedData
      .filter((h: any) => h.id && h.id > 0 && initialIds.has(h.id))
      .map((h: any) => updatePractice(h));

    const deleteTasks = deletedIds.map((id) => deletePractice(id));

    try {
      const results = await Promise.all([
        ...createTasks,
        ...updateTasks,
        ...deleteTasks,
      ]);
      console.log('Results:', results);
      fetchPractices();

      notifications.show({
        title: 'Success',
        message: 'Practices updated successfully',
        color: 'green',
        position: 'bottom-right',
      });
    } catch (error) {
      console.error('Error submitting practice form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit practice',
        color: 'red',
        position: 'bottom-right',
      });
    }
  };

  return (
    <Container>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        {initialData?.[0] && (
          <Paper shadow="xs" p="sm" withBorder>
            <h3>Primary Practice Location</h3>
            <Grid>
              <Grid.Col span={6}>
                <TextInput
                  label="Legal Business Name (as it appears on the W-9)"
                  {...register('practices.0.legalbusinessname')}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput label="Tax ID" {...register('practices.0.taxid')} />
              </Grid.Col>
              <Grid.Col span={6}>
                <Controller
                  name="practices.0.typeoftaxid"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label="Type of Tax ID"
                      data={[
                        { value: 'Group', label: 'Group' },
                        { value: 'Individual', label: 'Individual' },
                      ]}
                      clearable
                    />
                  )}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label="Organization (Type 2) NPI"
                  {...register('practices.0.organizationnpi')}
                />
              </Grid.Col>
            </Grid>
            <Paper shadow="xs" p="sm" withBorder>
              <h4>Practice Location Information</h4>
              <Grid>
                <Grid.Col span={6}>
                  <Controller
                    name="practices.0.phone"
                    control={control}
                    render={({ field }) => (
                      <Input.Wrapper label="Phone">
                        <Input
                          component={IMaskInput}
                          {...field}
                          mask="(000) 000-0000"
                          placeholder="(XXX) XXX-XXXX"
                        />
                      </Input.Wrapper>
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label={'\u0405treet 1'}
                    {...register('practices.0.street1')}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label={'\u0405treet 2'}
                    {...register('practices.0.street2')}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput label="City" {...register('practices.0.city')} />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name="practices.0.state"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="State"
                        data={STATES_ARRAY.map((state) => ({
                          value: state,
                          label: state,
                        }))}
                        clearable
                        searchable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput label="ZIP" {...register('practices.0.zip')} />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name="practices.0.country"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Country"
                        data={[{ value: 'US', label: 'United States' }]}
                        clearable
                        searchable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="County"
                    {...register('practices.0.county')}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Practice Location Email Address"
                    {...register('practices.0.practicelocationemailaddress')}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Practice Location Website"
                    {...register('practices.0.practicelocationwebsite')}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Appointment Scheduling Website"
                    {...register('practices.0.appointmentschedulingwebsite')}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Appointment Phone Number"
                    {...register('practices.0.appointmentphonenumber')}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name="practices.0.faxnumber"
                    control={control}
                    render={({ field }) => (
                      <Input.Wrapper label="Fax Number">
                        <Input
                          component={IMaskInput}
                          {...field}
                          mask="(000) 000-0000"
                          placeholder="(XXX) XXX-XXXX"
                        />
                      </Input.Wrapper>
                    )}
                  />
                </Grid.Col>
              </Grid>
              <Textarea
                label="Accessibility"
                {...register('practices.0.accessibility')}
              />
              <Controller
                name="practices.0.languages"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    {...field}
                    label="Languages"
                    data={LANGUAGES.map((language) => ({
                      value: language,
                      label: language,
                    }))}
                    searchable
                    clearable
                    value={field.value || []}
                  />
                )}
              />
              <h4>Office Manager Contact Information</h4>
              <Grid>
                <Grid.Col span={6}>
                  <TextInput
                    label="First Name"
                    {...register('practices.0.managerfname')}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Last Name"
                    {...register('practices.0.managerlname')}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name="practices.0.managerphone"
                    control={control}
                    render={({ field }) => (
                      <Input.Wrapper label="Phone">
                        <Input
                          component={IMaskInput}
                          {...field}
                          mask="(000) 000-0000"
                          placeholder="(XXX) XXX-XXXX"
                        />
                      </Input.Wrapper>
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Email"
                    {...register('practices.0.manageremail')}
                  />
                </Grid.Col>
              </Grid>
              <h4>Office Hours</h4>
              <Grid>
                <Grid.Col span={6}>
                  <Controller
                    name="practices.0.mondaystarttime"
                    control={control}
                    render={({ field }) => (
                      <TimeInput {...field} label="Monday Start Time" />
                    )}
                  />
                  <Controller
                    name="practices.0.mondayendtime"
                    control={control}
                    render={({ field }) => (
                      <TimeInput {...field} label="Monday End Time" />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name="practices.0.tuesdaystarttime"
                    control={control}
                    render={({ field }) => (
                      <TimeInput {...field} label="Tuesday Start Time" />
                    )}
                  />
                  <Controller
                    name="practices.0.tuesdayendtime"
                    control={control}
                    render={({ field }) => (
                      <TimeInput {...field} label="Tuesday End Time" />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name="practices.0.wednesdaystarttime"
                    control={control}
                    render={({ field }) => (
                      <TimeInput {...field} label="Wednesday Start Time" />
                    )}
                  />
                  <Controller
                    name="practices.0.wednesdayendtime"
                    control={control}
                    render={({ field }) => (
                      <TimeInput {...field} label="Wednesday End Time" />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name="practices.0.thursdaystarttime"
                    control={control}
                    render={({ field }) => (
                      <TimeInput {...field} label="Thursday Start Time" />
                    )}
                  />
                  <Controller
                    name="practices.0.thursdayendtime"
                    control={control}
                    render={({ field }) => (
                      <TimeInput {...field} label="Thursday End Time" />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name="practices.0.fridaystarttime"
                    control={control}
                    render={({ field }) => (
                      <TimeInput {...field} label="Friday Start Time" />
                    )}
                  />
                  <Controller
                    name="practices.0.fridayendtime"
                    control={control}
                    render={({ field }) => (
                      <TimeInput {...field} label="Friday End Time" />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name="practices.0.saturdaystarttime"
                    control={control}
                    render={({ field }) => (
                      <TimeInput {...field} label="Saturday Start Time" />
                    )}
                  />
                  <Controller
                    name="practices.0.saturdayendtime"
                    control={control}
                    render={({ field }) => (
                      <TimeInput {...field} label="Saturday End Time" />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name="practices.0.sundaystarttime"
                    control={control}
                    render={({ field }) => (
                      <TimeInput {...field} label="Sunday Start Time" />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name="practices.0.sundayendtime"
                    control={control}
                    render={({ field }) => (
                      <TimeInput {...field} label="Sunday End Time" />
                    )}
                  />
                </Grid.Col>
              </Grid>
            </Paper>
          </Paper>
        )}

        {fields.slice(1).map((item, index) => (
          <Paper key={item.id} shadow="xs" p="md" withBorder>
            <Group>
              <h3>Practice Location {index + 2}</h3>
              <Button onClick={() => toggleCollapse(index + 1)} size="xs">
                {collapsedIndices[index + 1] ? 'Expand' : 'Collapse'}
              </Button>
              <Button color="red" onClick={() => remove(index + 1)} size="xs">
                Remove
              </Button>
            </Group>
            <Collapse in={!collapsedIndices[index + 1]}>
              <Grid>
                <Grid.Col span={6}>
                  <TextInput
                    label="Legal Business Name (as it appears on the W-9)"
                    {...register(`practices.${index + 1}.legalbusinessname`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Tax ID"
                    {...register(`practices.${index + 1}.taxid`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`practices.${index + 1}.typeoftaxid`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Type of Tax ID"
                        data={[
                          { value: 'Group', label: 'Group' },
                          { value: 'Individual', label: 'Individual' },
                        ]}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Organization (Type 2) NPI"
                    {...register(`practices.${index + 1}.organizationnpi`)}
                  />
                </Grid.Col>
              </Grid>
              <Paper shadow="xs" p="sm" withBorder>
                <h4>Practice Location Information</h4>
                <Grid>
                  <Grid.Col span={6}>
                    <Controller
                      name={`practices.${index + 1}.phone`}
                      control={control}
                      render={({ field }) => (
                        <Input.Wrapper label="Phone">
                          <Input
                            component={IMaskInput}
                            {...field}
                            mask="(000) 000-0000"
                            placeholder="(XXX) XXX-XXXX"
                          />
                        </Input.Wrapper>
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      label={'\u0405treet 1'}
                      {...register(`practices.${index + 1}.street1`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      label={'\u0405treet 2'}
                      {...register(`practices.${index + 1}.street2`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      label="City"
                      {...register(`practices.${index + 1}.city`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Controller
                      name={`practices.${index + 1}.state`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          label="State"
                          data={STATES_ARRAY.map((state) => ({
                            value: state,
                            label: state,
                          }))}
                          clearable
                          searchable
                        />
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      label="ZIP"
                      {...register(`practices.${index + 1}.zip`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Controller
                      name={`practices.${index + 1}.country`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          label="Country"
                          data={[{ value: 'US', label: 'United States' }]}
                          clearable
                          searchable
                        />
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      label="County"
                      {...register(`practices.${index + 1}.county`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      label="Practice Location Email Address"
                      {...register(
                        `practices.${index + 1}.practicelocationemailaddress`,
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      label="Practice Location Website"
                      {...register(
                        `practices.${index + 1}.practicelocationwebsite`,
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      label="Appointment Scheduling Website"
                      {...register(
                        `practices.${index + 1}.appointmentschedulingwebsite`,
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      label="Appointment Phone Number"
                      {...register(
                        `practices.${index + 1}.appointmentphonenumber`,
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Controller
                      name={`practices.${index + 1}.faxnumber`}
                      control={control}
                      render={({ field }) => (
                        <Input.Wrapper label="Fax Number">
                          <Input
                            component={IMaskInput}
                            {...field}
                            mask="(000) 000-0000"
                            placeholder="(XXX) XXX-XXXX"
                          />
                        </Input.Wrapper>
                      )}
                    />
                  </Grid.Col>
                </Grid>
                <Textarea
                  label="Accessibility"
                  {...register(`practices.${index + 1}.accessibility`)}
                />
                <Controller
                  name={`practices.${index + 1}.languages`}
                  control={control}
                  render={({ field }) => (
                    <MultiSelect
                      {...field}
                      label="Languages"
                      data={LANGUAGES.map((language) => ({
                        value: language,
                        label: language,
                      }))}
                      searchable
                      clearable
                      value={field.value || []}
                    />
                  )}
                />
                <h4>Office Manager Contact Information</h4>
                <Grid>
                  <Grid.Col span={6}>
                    <TextInput
                      label="First Name"
                      {...register(`practices.${index + 1}.managerfname`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      label="Last Name"
                      {...register(`practices.${index + 1}.managerlname`)}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Controller
                      name={`practices.${index + 1}.managerphone`}
                      control={control}
                      render={({ field }) => (
                        <Input.Wrapper label="Phone">
                          <Input
                            component={IMaskInput}
                            {...field}
                            mask="(000) 000-0000"
                            placeholder="(XXX) XXX-XXXX"
                          />
                        </Input.Wrapper>
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      label="Email"
                      {...register(`practices.${index + 1}.manageremail`)}
                    />
                  </Grid.Col>
                </Grid>
                <h4>Office Hours</h4>
                <Grid>
                  <Grid.Col span={6}>
                    <Controller
                      name={`practices.${index + 1}.mondaystarttime`}
                      control={control}
                      render={({ field }) => (
                        <TimeInput {...field} label="Monday Start Time" />
                      )}
                    />
                    <Controller
                      name={`practices.${index + 1}.mondayendtime`}
                      control={control}
                      render={({ field }) => (
                        <TimeInput {...field} label="Monday End Time" />
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Controller
                      name={`practices.${index + 1}.tuesdaystarttime`}
                      control={control}
                      render={({ field }) => (
                        <TimeInput {...field} label="Tuesday Start Time" />
                      )}
                    />
                    <Controller
                      name={`practices.${index + 1}.tuesdayendtime`}
                      control={control}
                      render={({ field }) => (
                        <TimeInput {...field} label="Tuesday End Time" />
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Controller
                      name={`practices.${index + 1}.wednesdaystarttime`}
                      control={control}
                      render={({ field }) => (
                        <TimeInput {...field} label="Wednesday Start Time" />
                      )}
                    />
                    <Controller
                      name={`practices.${index + 1}.wednesdayendtime`}
                      control={control}
                      render={({ field }) => (
                        <TimeInput {...field} label="Wednesday End Time" />
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Controller
                      name={`practices.${index + 1}.thursdaystarttime`}
                      control={control}
                      render={({ field }) => (
                        <TimeInput {...field} label="Thursday Start Time" />
                      )}
                    />
                    <Controller
                      name={`practices.${index + 1}.thursdayendtime`}
                      control={control}
                      render={({ field }) => (
                        <TimeInput {...field} label="Thursday End Time" />
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Controller
                      name={`practices.${index + 1}.fridaystarttime`}
                      control={control}
                      render={({ field }) => (
                        <TimeInput {...field} label="Friday Start Time" />
                      )}
                    />
                    <Controller
                      name={`practices.${index + 1}.fridayendtime`}
                      control={control}
                      render={({ field }) => (
                        <TimeInput {...field} label="Friday End Time" />
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Controller
                      name={`practices.${index + 1}.saturdaystarttime`}
                      control={control}
                      render={({ field }) => (
                        <TimeInput {...field} label="Saturday Start Time" />
                      )}
                    />
                    <Controller
                      name={`practices.${index + 1}.saturdayendtime`}
                      control={control}
                      render={({ field }) => (
                        <TimeInput {...field} label="Saturday End Time" />
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Controller
                      name={`practices.${index + 1}.sundaystarttime`}
                      control={control}
                      render={({ field }) => (
                        <TimeInput {...field} label="Sunday Start Time" />
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Controller
                      name={`practices.${index + 1}.sundayendtime`}
                      control={control}
                      render={({ field }) => (
                        <TimeInput {...field} label="Sunday End Time" />
                      )}
                    />
                  </Grid.Col>
                </Grid>
              </Paper>
            </Collapse>
          </Paper>
        ))}
        <Group mt="md">
          <Button
            onClick={() =>
              append({
                legalbusinessname: '',
                typeoftaxid: '',
                organizationnpi: '',
                phone: '',
                street1: '',
                street2: '',
                city: '',
                state: '',
                zip: '',
                country: 'US',
                county: '',
                practicelocationemailaddress: '',
                practicelocationwebsite: '',
                appointmentschedulingwebsite: '',
                appointmentphonenumber: '',
                faxnumber: '',
                accessibility: '',
                languages: [],
                mondaystarttime: '',
                mondayendtime: '',
                tuesdaystarttime: '',
                tuesdayendtime: '',
                wednesdaystarttime: '',
                wednesdayendtime: '',
                thursdaystarttime: '',
                thursdayendtime: '',
                fridaystarttime: '',
                fridayendtime: '',
                saturdaystarttime: '',
                saturdayendtime: '',
                sundaystarttime: '',
                sundayendtime: '',
                managerfname: '',
                managerlname: '',
                managerphone: '',
                manageremail: '',
                primary: 'N',
              })
            }
          >
            Add Practice
          </Button>
        </Group>
        <Group mt="md">
          <Button type="submit">Save</Button>
        </Group>
      </form>
    </Container>
  );
};

export default PracticeForm;
