import React, { useState } from 'react';
import {
  PasswordInput,
  Button,
  Paper,
  Container,
  Title,
  Center,
} from '@mantine/core';
import { useParams, useNavigate } from 'react-router-dom';
import { showNotification } from '@mantine/notifications';
import useHttpClient from '../hooks/useHttpClient';

export default function PasswordReset() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();
  const httpClient = useHttpClient();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords don't match");
      return;
    }
    if (password.length < 8) {
      alert('Password must be at least 8 characters');
      return;
    }
    try {
      await httpClient.post('/api/auth/reset-password', {
        token,
        password,
      });
      showNotification({
        title: 'Success',
        message: 'Your password has been reset successfully.',
        color: 'green',
      });
      navigate('/login');
    } catch (error) {
      showNotification({
        title: 'Error',
        message: 'Failed to reset password.',
        color: 'red',
      });
    }
  };

  return (
    <Container size={420} mt={100} style={{ background: 'none' }}>
      <Paper withBorder shadow="md" p={30} radius="md" mt="lg">
        <form onSubmit={handleSubmit}>
          <Center mb="lg">
            <Title order={2}>Set New Password</Title>
          </Center>
          <PasswordInput
            label="New Password"
            placeholder="Enter your new password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            maxLength={50}
            mb="md"
          />
          <PasswordInput
            label="Confirm New Password"
            placeholder="Confirm your new password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            mb="md"
          />
          <Center mt="md">
            <Button type="submit">Reset Password</Button>
          </Center>
        </form>
      </Paper>
    </Container>
  );
}
