import React from 'react';
import { Controller, FieldError, useFormState } from 'react-hook-form';
import { DateInput } from '@mantine/dates';
import { TextInput } from '@mantine/core';
import { IMaskInput } from 'react-imask';
import { InputBase } from '@mantine/core';

interface CustomDateInputProps {
  control: any; // This should be the control from useForm
  name: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  preventFutureDates?: boolean;
}

const CustomDateInput: React.FC<CustomDateInputProps> = ({
  control,
  name,
  label = 'Date of Birth',
  required = true,
  disabled = false,
  preventFutureDates = false,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required,
        validate: {
          customRule: (value) => {
            try {
              if (isNaN(new Date(value).getTime())) {
                return 'Please enter a valid date following the format: YYYY-MM-DD.';
              }
            } catch (e) {
              console.log(e);
              return 'Please enter a valid date.';
            }
            if (preventFutureDates && new Date(value) > new Date()) {
              return 'Please dont enter a future date.';
            }
            return true; // No error
          },
        },
      }}
      render={({ field, fieldState: { error } }) => {
        let value;
        try {
          value = new Date(field.value);
          if (isNaN(value.getTime())) {
            value = '';
          }
        } catch (e) {
          value = '';
        } finally {
          value = value?.toString(0, 10);
        }

        return (
          <>
            <InputBase
              placeholder="YYYY-MM-DD"
              value={field.value}
              label={label}
              disabled={disabled}
              required={required}
              component={IMaskInput}
              mask="0000-00-00"
              onChange={(evt) => {
                field.onChange(evt);
              }}
            />
            {error && (
              <span style={{ color: 'red', fontSize: '0.8em' }}>
                {error.message}
              </span>
            )}
          </>
        );
      }}
    />
  );
};

export default CustomDateInput;
