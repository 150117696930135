/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from 'react';
import { Group, Text, Avatar, Button } from '@mantine/core';
import RyzeLogo from './RyzeLogo';
import WecareLogo from './WecareLogo';
import { DoubleNavbar } from './DoubleNavbar';
import { AppShell, Burger } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { mainMenuList, subMenuList } from './menu.config';
import { User } from 'tabler-icons-react';
import { useAuth } from './AuthProvider';
import { useLocation } from 'react-router-dom';

interface PortalProps {
  subMenuKey?: string;
  children: React.ReactNode;
}

const AppShellWrapper: React.FC<PortalProps> = ({ children, subMenuKey }) => {
  // main menu
  const location = useLocation();
  const pathname = location.pathname;
  const mainMenuObject = useMemo(() => {
    return mainMenuList.find((menuItem) =>
      menuItem.routes.some((route) => {
        if (route === '/') {
          return pathname === '/';
        } else if (route instanceof RegExp) {
          return route.test(pathname);
        } else {
          return pathname.startsWith(route);
        }
      }),
    );
  }, [pathname]);
  const mainMenu = mainMenuObject?.menu;
  const layout = mainMenuObject?.layout || 'member';

  // submenu menu
  const [opened, { toggle }] = useDisclosure();
  const subMenu = subMenuList[subMenuKey] || {};

  const auth = useAuth();
  const privileges = auth.getPrivileges();
  const username = privileges[0].username;
  const type = privileges[0].type
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase());

  return (
    <AppShell
      header={{ height: 75 }}
      navbar={{
        width: subMenu.menu ? 320 : mainMenu ? 235 : 0,
        breakpoint: 'sm',
        collapsed: { mobile: opened },
      }}
      style={{ background: 'none' }}
      padding="lg"
    >
      <AppShell.Header>
        <Group justify="space-between" style={{ padding: '0 20px' }}>
          <Group align="center">
            <Burger
              opened={opened}
              onClick={toggle}
              hiddenFrom="sm"
              size="sm"
            />
            {layout === 'wecareadmin' || layout === 'wecaremember' ? (
              <a
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  auth.goHome();
                }}
              >
                <WecareLogo />
              </a>
            ) : (
              <a
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  auth.goHome();
                }}
              >
                <RyzeLogo />
              </a>
            )}
            {layout === 'wecaremember' ? (
              <Button
                component="a"
                href="https://www.ryzehealth.com/join"
                target="_blank"
                rel="noopener noreferrer"
              >
                Signup for Ryze
              </Button>
            ) : null}
          </Group>
        </Group>
      </AppShell.Header>

      {mainMenu && (
        <AppShell.Navbar p="sm">
          <DoubleNavbar
            location={location}
            mainMenu={mainMenu}
            subMenu={subMenu}
          />
        </AppShell.Navbar>
      )}

      <AppShell.Main onClick={() => !opened && toggle()}>
        {children}
      </AppShell.Main>
    </AppShell>
  );
};

export default AppShellWrapper;
