// helpers/loadScript.ts
export function loadScript(src: string): Promise<void> {
  return new Promise((resolve, reject) => {
    // Check if the script is already included in the page
    const existingScript = document.querySelector(`script[src="${src}"]`);

    if (existingScript) {
      // If the script is already loaded, resolve immediately
      if (window.google) {
        resolve();
      } else {
        // If the script tag exists but hasn't loaded yet, wait for it to load
        existingScript.addEventListener('load', () => {
          resolve();
        });
      }
      return;
    }

    // Create a new script element
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.defer = true;

    script.onload = () => {
      resolve();
    };

    script.onerror = (err) => {
      reject(err);
    };

    document.head.appendChild(script);
  });
}
