import React, { memo, useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { plans } from '../../enums/plans';
import { useAuth } from '../hooks/useAuth';
import useHttpClient from '../hooks/useHttpClient';
import { Group, SegmentedControl, Text } from '@mantine/core';
import { Tooltip as RechartsTooltip } from 'recharts';

const PlanGraphs: React.FC = () => {
    const { organizationId } = useAuth();
    const httpClient = useHttpClient();
    const [premiums, setPremiums] = useState(null);
    const [costBenefitData, setCostBenefitData] = useState(null);
    const [maxExpenseData, setMaxExpenseData] = useState(null);
    const [costBenefitDataHSA, setCostBenefitDataHSA] = useState(null);
    const [maxExpenseDataHSA, setMaxExpenseDataHSA] = useState(null);
    const [chartType, setChartType] = useState<'Cost Benefit Comparison' | 'Maximum Potential Annual Expense'>('Cost Benefit Comparison');
    const [fade, setFade] = useState(true);
    const [maxCostBenefitValue, setMaxCostBenefitValue] = useState(0);
    const [maxMaxExpenseValue, setMaxMaxExpenseValue] = useState(0);

    useEffect(() => {
        const fetchPremiums = async () => {
            const premiumNumbers = await httpClient.get(`/api/premium/all`);
            if (premiumNumbers.data.length > 0) {
                setPremiums(premiumNumbers.data);
            }
        };
        if (organizationId) {
            fetchPremiums();
        }
    }, [organizationId]);

    useEffect(() => {
        if (premiums) {
            let familyTier = premiums[0].tiers[0].tierName;
            if (familyTier !== 'Individual') {
                familyTier = 'Family';
            }

            // Combined data for Cost Benefit Comparison (Monthly Premium) and Maximum Potential Annual Expense (Annual Premium)
            const combinedData = [
                { name: 'Plan 1', premium: Math.round(premiums[0].totalPremium), deductible: plans['Plan 1']['INN']['Deductible'][familyTier], oopMax: plans['Plan 1']['INN']['OOP Max'][familyTier] - plans['Plan 1']['INN']['Deductible'][familyTier] },
                { name: 'Plan 2', premium: Math.round(premiums[1].totalPremium), deductible: plans['Plan 2']['INN']['Deductible'][familyTier], oopMax: plans['Plan 2']['INN']['OOP Max'][familyTier] - plans['Plan 2']['INN']['Deductible'][familyTier] },
                { name: 'Plan 3', premium: Math.round(premiums[2].totalPremium), deductible: plans['Plan 3']['INN']['Deductible'][familyTier], oopMax: plans['Plan 3']['INN']['OOP Max'][familyTier] - plans['Plan 3']['INN']['Deductible'][familyTier] },
                { name: 'Plan 4', premium: Math.round(premiums[3].totalPremium), deductible: plans['Plan 4']['INN']['Deductible'][familyTier], oopMax: plans['Plan 4']['INN']['OOP Max'][familyTier] - plans['Plan 4']['INN']['Deductible'][familyTier] },
                { name: 'Plan 5', premium: Math.round(premiums[4].totalPremium), deductible: plans['Plan 5']['INN']['Deductible'][familyTier], oopMax: plans['Plan 5']['INN']['OOP Max'][familyTier] - plans['Plan 5']['INN']['Deductible'][familyTier] },
            ];

            // Set monthly data for standard and HSA plans for Cost Benefit Comparison
            setCostBenefitData(combinedData.slice(0, 3));  // Standard plans
            setCostBenefitDataHSA(combinedData.slice(3));  // HSA plans

            // Calculate max for Cost Benefit Comparison (monthly)
            const maxBenefitValue = Math.max(
                ...combinedData.map(plan => Math.max(plan.premium, plan.oopMax))
            );
            setMaxCostBenefitValue(roundUpToNearest5000(maxBenefitValue));

            // Calculate annual premiums for Maximum Potential Annual Expense
            const annualData = combinedData.map(plan => ({
                ...plan,
                premium: plan.premium * 12  // Convert to annual premium
            }));

            // Set annual data for standard and HSA plans for Maximum Potential Annual Expense
            setMaxExpenseData(annualData.slice(0, 3));     // Standard plans
            setMaxExpenseDataHSA(annualData.slice(3));     // HSA plans

            // Calculate max for Maximum Potential Annual Expense
            const maxExpenseValue = Math.max(
                ...annualData.map(plan => plan.premium + plan.deductible + plan.oopMax)
            );
            setMaxMaxExpenseValue(roundUpToNearest5000(maxExpenseValue));
        }
    }, [premiums]);


    const roundUpToNearest5000 = (value: number) => Math.ceil(value / 5000) * 5000;

    const handleChartSwitch = (type) => {
        setFade(false);
        setTimeout(() => {
            setChartType(type);
            setFade(true);
        }, 200);
    };

    const renderMaxExpenseTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            // Get the plan name from the payload (assuming it’s in the first entry)
            const planName = payload[0].payload.name;

            // Calculate the total maximum expense by adding premium, deductible, and oopMax
            const totalExpense = payload[0].payload.premium +
                payload[0].payload.deductible +
                payload[0].payload.oopMax;

            return (
                <div style={{ backgroundColor: 'white', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }}>
                    <p style={{ margin: 0 }}>{planName}</p>
                    <p style={{ margin: 0, color: '#4a90e2' }}>Total Potential Expense: ${totalExpense}</p>
                </div>
            );
        }
        return null;
    };

    const renderCostBenefitTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            // Get the plan name from the payload (assuming it’s in the first entry)
            const planName = payload[0].payload.name;

            // Calculate the total Out of Pocket Max by adding oopMax and deductible
            const oopMaxTotal = payload.find(item => item.dataKey === "oopMax")?.payload.oopMax +
                payload.find(item => item.dataKey === "deductible")?.payload.deductible;

            // Define colors for each bar type
            const colors = {
                premium: "#5f9ea0",
                deductible: "#9370db",
                oopMax: "#4682b4"
            };

            return (
                <div style={{ backgroundColor: 'white', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }}>
                    <p style={{ margin: 0 }}>{planName}</p>
                    {payload.map((entry, index) => (
                        <p key={`item-${index}`} style={{ margin: 0, color: colors[entry.dataKey] }}>
                            {entry.name === "Out of Pocket Max"
                                ? `${entry.name}: $${oopMaxTotal}`
                                : `${entry.name}: $${entry.value}`}
                        </p>
                    ))}
                </div>
            );
        }
        return null;
    };

    const renderCostBenefitChart = (data) => (
        <BarChart key="cost-benefit" data={data} barSize={45}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis tickFormatter={(value) => `$${value}`} domain={[0, maxCostBenefitValue]} />
            <RechartsTooltip content={renderCostBenefitTooltip} />
            <Legend />
            <Bar dataKey="premium" fill="#5f9ea0" name="Monthly Premium" radius={[7, 7, 0, 0]} animationDuration={1000} />
            <Bar dataKey="deductible" stackId="a" fill="#9370db" name="Deductible" animationDuration={500} />
            <Bar dataKey="oopMax" stackId="a" fill="#4682b4" name="Out of Pocket Max" radius={[7, 7, 0, 0]} animationDuration={500} animationBegin={500} />
        </BarChart>
    );

    const renderMaxExpenseChart = (data) => (
        <BarChart key="max-expense" data={data} barSize={45}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis tickFormatter={(value) => `$${value}`} domain={[0, maxMaxExpenseValue]} />
            <Tooltip content={renderMaxExpenseTooltip} />
            <Legend />
            <Bar dataKey="premium" stackId="a" fill="#5f9ea0" name="Annual Premium" animationDuration={500} />
            <Bar dataKey="deductible" stackId="a" fill="#9370db" name="Deductible" animationDuration={250} animationBegin={500} />
            <Bar dataKey="oopMax" stackId="a" fill="#4682b4" name="Out of Pocket Max" radius={[7, 7, 0, 0]} animationDuration={250} animationBegin={750} />
        </BarChart>
    );

    return (
        <div>
            <Group style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
                <SegmentedControl
                    value={chartType}
                    onChange={(value) => handleChartSwitch(value)}
                    data={[
                        { label: 'Cost Benefit Comparison', value: 'Cost Benefit Comparison' },
                        { label: 'Maximum Potential Annual Expense', value: 'Maximum Potential Annual Expense' },
                    ]}
                    styles={{
                        root: {
                            backgroundColor: 'rgb(235, 232, 250)',
                            borderRadius: '8px',
                            padding: '5px',
                        },
                        label: {
                            fontSize: '14px',
                            color: 'rgb(63, 41, 205)',
                        },
                    }}
                />
            </Group>

            <Text ta="center" style={{ marginBottom: '20px' }}>
                {chartType === 'Cost Benefit Comparison'
                    ? 'This chart helps you weigh your costs against your benefits by comparing each plan’s monthly premium (what you pay) to its deductible and out-of-pocket maximum (the protection you receive). Plans with lower deductibles and out-of-pocket maximums provide greater financial protection, limiting your potential costs when you need care.'
                    : 'This chart illustrates the maximum potential financial risk for each plan, showing the highest total amount you could pay in a year, including premiums, deductibles, and out-of-pocket maximums. It helps you assess your worst-case financial exposure under each plan.'}
            </Text>

            <Text ta="center" style={{ marginBottom: '10px', color: '#666' }}>
                Hover over a bar to see the associated numbers.
            </Text>

            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', opacity: fade ? 1 : 0, transition: 'opacity 0.2s ease-in-out' }}>
                {costBenefitData && chartType === 'Cost Benefit Comparison' && (
                    <>
                        <div style={{ width: '48%', height: 300 }}>
                            <h3 style={{ textAlign: 'center' }}>Standard Plans</h3>
                            <ResponsiveContainer>
                                {renderCostBenefitChart(costBenefitData)}
                            </ResponsiveContainer>
                        </div>
                        <div style={{ width: '48%', height: 300 }}>
                            <h3 style={{ textAlign: 'center' }}>HSA Plans</h3>
                            <ResponsiveContainer>
                                {renderCostBenefitChart(costBenefitDataHSA)}
                            </ResponsiveContainer>
                        </div>
                    </>
                )}

                {maxExpenseData && chartType === 'Maximum Potential Annual Expense' && (
                    <>
                        <div style={{ width: '48%', height: 300 }}>
                            <h3 style={{ textAlign: 'center' }}>Standard Plans</h3>
                            <ResponsiveContainer>
                                {renderMaxExpenseChart(maxExpenseData)}
                            </ResponsiveContainer>
                        </div>
                        <div style={{ width: '48%', height: 300 }}>
                            <h3 style={{ textAlign: 'center' }}>HSA Plans</h3>
                            <ResponsiveContainer>
                                {renderMaxExpenseChart(maxExpenseDataHSA)}
                            </ResponsiveContainer>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default memo(PlanGraphs);
