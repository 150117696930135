import React, { useState, useEffect } from 'react';
import useHttpClient from '../hooks/useHttpClient';
import axios from 'axios';
import {
  Button,
  TextInput,
  Text,
  ThemeIcon,
  Group,
  Modal,
} from '@mantine/core';
import { Check } from 'tabler-icons-react';

interface PlanPdfUploadProps {
  planUploaded: boolean;
  subscriberMemberId: number;
  enrollmentcode: string;
}

const PlanPdfUpload: React.FC<PlanPdfUploadProps> = ({
  planUploaded,
  subscriberMemberId,
  enrollmentcode,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [message, setMessage] = useState('');
  const [isPlanUploaded, setIsPlanUploaded] = useState(planUploaded);
  const httpClient = useHttpClient();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile?.type === 'application/pdf') {
      setFile(selectedFile);
      setMessage('');
    } else {
      setMessage('Please upload a PDF file.');
    }
  };

  const uploadFile = async () => {
    if (!file) {
      setMessage('Please select a file to upload');
      return;
    }

    try {
      const response = await httpClient.get('/api/plans/generate-signed-url', {
        params: { fileName: file.name, fileType: file.type, enrollmentcode },
      });

      const { signedUrl } = response.data;
      await axios.put(signedUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      });

      // Update the backend to mark the plan as uploaded
      await httpClient.put(`/api/subscriber/${subscriberMemberId}`, {
        planuploaded: true,
      });

      setMessage('File uploaded successfully');
      setIsPlanUploaded(true);
    } catch (error) {
      console.error('Error uploading file:', error);
      setMessage('File upload failed');
    }
  };

  return (
    <>
      {isPlanUploaded ? (
        <Group>
          <ThemeIcon color="teal" radius="xl" size="lg">
            <Check size={24} />
          </ThemeIcon>
          <Text color="teal" size="md">
            Plan uploaded successfully. Thank you!
          </Text>
        </Group>
      ) : (
        <>
          <Text size="md" mb="sm">
            Help us help you by sharing your current health plan. Uploading a
            plan document can ensure we provide the best recommendations
            tailored to your needs.
          </Text>
          <TextInput
            type="file"
            onChange={handleFileChange}
            accept="application/pdf"
          />
          <Button onClick={uploadFile} mt="sm" color="blue">
            Upload PDF
          </Button>
          {message && <Text mt="sm">{message}</Text>}
        </>
      )}
    </>
  );
};

export default PlanPdfUpload;
