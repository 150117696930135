import { Image } from '@mantine/core';
import { useComputedColorScheme } from '@mantine/core';

const WecareLogo: React.FC = () => {
  const computedColorScheme = useComputedColorScheme('light');

  return (
    <Image
      src={
        computedColorScheme === 'dark'
          ? `${process.env.PUBLIC_URL}/WecareLogoWhite.png`
          : `${process.env.PUBLIC_URL}/WecareLogoBlack.png`
      }
      alt="Wecare Logo"
      style={{
        position: 'relative',
        padding: '8px 0px',
        width: '200px',
        display: 'flex',
        alignItems: 'left',
      }}
    />
  );
};

export default WecareLogo;
