import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import useHttpClient from './hooks/useHttpClient';
import { SubscriberMember } from './../types/subscribermember.types';
import { useAuth } from './hooks/useAuth';

interface WecareMemberContextProps {
  isNewMember: boolean;
  setIsNewMember: (isNew: boolean) => void;
  memberId: number;
  setMemberId: (id: number) => void;
  member: SubscriberMember | null;
  isLoading: boolean;
  error: string | null;
}

const WecareMemberContext = createContext<WecareMemberContextProps | undefined>(undefined);

interface WecareMemberProviderProps {
  children: ReactNode;
}

export const WecareMemberProvider: React.FC<WecareMemberProviderProps> = ({
  children,
}) => {
  const [member, setMember] = useState<SubscriberMember | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isNewMember, setIsNewMember] = useState<boolean>(false);
  const [memberId, setMemberId] = useState<number>(0);
  const httpClient = useHttpClient();
  const { wecareMemberId } = useAuth();
  console.log(wecareMemberId);

  const fetchMemberData = async (id: number) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await httpClient.get(`/api/members/${id}`);
      const data = await response.data;
      setMember(data);
      setMemberId(id);
    } catch (err) {
      console.error('Error fetching wecare member data', err);
      setError('Failed to fetch wecare member data');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (wecareMemberId !== null && !isNewMember) {
      fetchMemberData(wecareMemberId);
    }
  }, [wecareMemberId, isNewMember]);

  return (
    <WecareMemberContext.Provider
      value={{
        isNewMember,
        setIsNewMember,
        memberId,
        setMemberId,
        member,
        isLoading,
        error,
      }}
    >
      {children}
    </WecareMemberContext.Provider>
  );
};

export const useWecareMemberContext = (): WecareMemberContextProps => {
  const context = useContext(WecareMemberContext);
  if (!context) {
    throw new Error('useWecareMemberContext must be used within a WecareMemberProvider');
  }
  return context;
};
