import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';
import { Organization } from '../../types/organization.types';
import useHttpClient from '../hooks/useHttpClient';
import { useAuth } from '../hooks/useAuth';

interface OrganizationContextProps {
  organizationId: number;
  organization: Organization | null;
  setOrganization: (organization: Organization | null) => void;
}

const OrganizationContext = createContext<OrganizationContextProps | undefined>(
  undefined,
);

interface OrganizationProviderProps {
  children: ReactNode;
}

export const OrganizationProvider: React.FC<OrganizationProviderProps> = ({
  children,
}) => {
  const { organizationId } = useAuth();
  const [organization, setOrganization] = useState<Organization | null>(null);
  const httpClient = useHttpClient();

  useEffect(() => {
    const fetchOrganization = async () => {
      if (organizationId) {
        try {
          const response = await httpClient.get(`/api/organization/`);
          const data = response.data;
          setOrganization(data);
        } catch (error) {
          console.error('Error fetching organization', error);
        }
      }
    };

    fetchOrganization();
  }, [organizationId]);

  return (
    <OrganizationContext.Provider
      value={{ organizationId, organization, setOrganization }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

export const useOrganizationContext = (): OrganizationContextProps => {
  const context = useContext(OrganizationContext);
  if (!context) {
    throw new Error(
      'useOrganizationContext must be used within an OrganizationProvider',
    );
  }
  return context;
};
