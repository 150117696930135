import React from 'react';
import { Container, Button, Title, Text, Group, Paper } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

const InsurancePreRegistrationLandingPage: React.FC = () => {
  const navigate = useNavigate();

  const handlePracticeClick = () => {
    navigate('/insurance/register/organization');
  };

  const handlePhysicianClick = () => {
    navigate('/insurance/register/physician');
  };

  return (
    <Container style={{ textAlign: 'center', marginTop: '100px' }}>
      <Paper withBorder shadow="md" p="xl" radius="md">
        <Title order={1}>
          Ryze Health Member <br /> Registration
        </Title>
        <Text mt="md" size="lg">
          We are excited that you're taking this important step toward lowering your health insurance costs. It's all about making smart choices that benefit you.
          <br /><br />
          Please let us know if you're joining as an independent provider or a practice owner
        </Text>

        <Group justify="center" mt="xl">
          <Button size="lg" onClick={handlePhysicianClick}>
            I am a physician
          </Button>
          <Button size="lg" onClick={handlePracticeClick} variant="outline">
            I represent a practice
          </Button>
        </Group>
      </Paper>
    </Container>
  );
};

export default InsurancePreRegistrationLandingPage;
