import {
  Modal,
  Button,
  Title,
  Text,
  FileInput,
  Flex,
  Anchor,
  Alert,
  Loader,
} from '@mantine/core';
import { IconAlertCircle, IconFileTypeCsv } from '@tabler/icons-react';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { Upload } from 'tabler-icons-react';
import Papa from 'papaparse';
import { notifications } from '@mantine/notifications';
import useHttpClient from '../hooks/useHttpClient';
import validator from 'validator';

// const CSV_TEMPLATE_URL =
//   'https://ryze-public.s3.us-east-2.amazonaws.com/ryze-employee-template.csv';

const CSV_TEMPLATE_URL = '/ryze-employee-template.csv';

export default function UploadCSV({
  showCsvUploadModal,
  setShowCsvUploadModal,
  cb,
}) {
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alertError, setAlertError] = useState<ReactNode>(null);
  const httpClient = useHttpClient();

  const downloadTemploate = () => {
    const link = document.createElement('a');
    link.href = CSV_TEMPLATE_URL;
    link.setAttribute('download', 'template.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleUpload = (event) => {
    if (file) {
      parseCSVFile(file);
    }
  };

  const validateGender = (idx, value) => {
    if (!value) return true;

    const passed = ['F', 'M'].includes(value);
    if (!passed) {
      setAlertError(
        <>
          <Title order={6}>Error on '{value}'</Title>
          Line:{idx + 1} Please use <strong>M</strong> or <strong>F</strong> for
          gender. Please check and correct the input.
        </>,
      );
    }
    return passed;
  };

  const validateDateFormat = (idx, dateString) => {
    if (!dateString) return true;

    const isValidDateFormat =
      /^\d{4}-\d{2}-\d{2}$/.test(dateString) || // YYYY-MM-DD format
      /^\d{1,2}\/\d{1,2}\/\d{2}$/.test(dateString); // M/D/YY or MM/DD/YY format
    const passed = isValidDateFormat && validator.isBefore(dateString); // before today;

    if (!passed) {
      setAlertError(
        <>
          <Title order={6}>Error on '{dateString}'</Title>
          Line: {idx + 1} Please use <strong>YYYY-MM-DD</strong> or{' '}
          <strong>M/D/YY</strong> for birthdate.
        </>,
      );
    }

    return passed;
  };
  const parseCSVFile = (file) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const data = results.data;

        // Validate columns in the first row
        const allowedColumns = [
          'firstname',
          'lastname',
          'email',
          'gender',
          'birthdate',
        ];
        const parsedColumns = Object.keys(data[0] || {});

        // Check if any column is not allowed
        const invalidColumns = parsedColumns.filter(
          (column) => !allowedColumns.includes(column),
        );

        if (invalidColumns.length > 0) {
          notifications.show({
            title: 'Invalid Columns',
            message: `The following columns are not allowed: ${invalidColumns.join(
              ', ',
            )}`,
            color: 'red',
            position: 'bottom-right',
          });
          return;
        }

        // Validate each entry for missing or invalid fields
        const invalidEntries = data.filter(
          (item, idx) =>
            !item.firstname ||
            !item.lastname ||
            !item.email ||
            (item.email && !validator.isEmail(item.email)) ||
            !validateDateFormat(idx, item.birthdate) ||
            !validateGender(idx, item.gender),
        );

        if (invalidEntries.length > 0) {
          notifications.show({
            title: 'Invalid Data',
            message: 'Some entries have missing or invalid fields.',
            color: 'red',
            position: 'bottom-right',
          });
          return;
        }

        // If validation passes, sanitize and submit the data
        const sanitizedData = data.map((item) => ({
          firstname: item.firstname,
          lastname: item.lastname,
          email: item.email,
          birthdate: item.birthdate,
          gender: item.gender,
        }));

        validateAndSubmitData(sanitizedData);
      },
      error: function (error) {
        console.log(error);
        notifications.show({
          title: 'Error',
          message: error,
          color: 'red',
          position: 'bottom-right',
        });
      },
    });
  };

  const validateAndSubmitData = (data) => {
    const invalidEntries = data.filter(
      (item, idx) =>
        !item.firstname ||
        !item.lastname ||
        !item.email ||
        !validator.isEmail(item.email) ||
        !validateDateFormat(idx, item.birthdate) ||
        !validateGender(idx, item.gender),
    );
    if (invalidEntries.length > 0) {
      // Show errors
      notifications.show({
        title: 'Invalid Data',
        message: 'Some entries have missing or invalid fields.',
        color: 'red',
        position: 'bottom-right',
      });
      return;
    }

    // Submit data to backend
    submitBulkData(data);
  };

  const submitBulkData = async (data) => {
    try {
      setLoading(true);
      const { data: result } = await httpClient.post(
        '/api/subscriber/employee/bulk',
        {
          data,
        },
      );

      console.log(':::', result);

      notifications.show({
        title: 'Success',
        message: 'Employees added successfully',
        color: 'green',
        position: 'bottom-right',
      });
      setShowCsvUploadModal(false);
      cb(); // Refresh the members list
    } catch (error) {
      if (error?.response?.data) {
        setAlertError(
          <>
            <Title order={6}>Error</Title>
            {error?.response?.data}
          </>,
        );
      }
      notifications.show({
        title: 'Error',
        message: 'Failed to add employees.',
        color: 'red',
        position: 'bottom-right',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        size="lg"
        opened={showCsvUploadModal}
        onClose={() => setShowCsvUploadModal(false)}
        title={
          <Title order={2} m={0}>
            <IconFileTypeCsv /> Upload CSV
          </Title>
        }
      >
        <p>
          <>
            Upload a CSV file with columns for <strong>firstname</strong>,{' '}
            <strong>lastname</strong>, <strong>gender</strong>,{' '}
            <strong>birthdate</strong> and <strong>email</strong>. Download the
            template by clicking{' '}
            <Anchor href="#" onClick={downloadTemploate} target="_blank">
              here
            </Anchor>
            .
            <br />
            <br />
            Use <strong>M</strong> or <strong>F</strong> for gender and use the
            date format <strong>YYYY-MM-DD</strong> or <strong>MM/DD/YY</strong>{' '}
            for birthdate. For example 1989-11-02 or 08/02/60
          </>
        </p>
        <FileInput
          ref={fileInputRef}
          placeholder="Select a CSV file"
          label="CSV file"
          value={file}
          onChange={setFile}
        />

        {alertError && (
          <Alert
            variant="light"
            color="red"
            radius="xs"
            mt="md"
            withCloseButton
            onClose={() => setAlertError(null)}
            title=""
            icon={<IconAlertCircle />}
            mb="md"
          >
            {alertError}
          </Alert>
        )}

        <Flex justify="flex-end" mt="md">
          <Button
            mr="xs"
            color="red"
            onClick={() => setShowCsvUploadModal(false)}
          >
            Close
          </Button>

          <Button onClick={handleUpload} disabled={!file || loading}>
            {loading ? (
              <>
                <Loader color="blue" size="xs" mr="xs" />
                Please wait...
              </>
            ) : (
              <>
                <Upload />
                Upload
              </>
            )}
          </Button>
        </Flex>
      </Modal>
    </>
  );
}
